import React, {useEffect, useState} from "react";
import {Layout, Menu, Button} from "antd";
import logo from '../Common/Images/logo-full.png'
import {
  DatabaseOutlined,
  LineChartOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UserOutlined,
  ApiOutlined,
  GroupOutlined,
  FileOutlined
} from '@ant-design/icons';
import {Switch} from "react-router";
import HomePage from "./HomePage";
import isMobile from "is-mobile";
import FAQ from "./FAQ";
import {Route} from "react-router";
import {Link} from "react-router-dom";
import {logout} from '../Common/ApiCall/Logout'
import ExtractUsers from "./ExtractUsers";
import ViewUsers from "./ViewUser";
import AllProcesses from "./AllProcesses";
import MyAccount from "./MyAccount";
import ContentWrapper from "../Components/ContentWrapper";
import AddLeads from "./AddLeads";
import ManageLeads from './ManageLeads'
import ViewEditLead from './ManageLeads/ViewEditLead'
import ManageData from './ManageData'
import ManageTasks from './ManageTasks'
import SimilarUsers from "./SimilarUsers";
import Influencers from "./Influencers";
import InfluencerList from "./InfluencerList";

const {Header, Sider} = Layout

const MenuItems = [
  {
    key: "1",
    title: 'View Users',
    link: '/',
    icon: <UnorderedListOutlined />
  },
  {
    key: "3",
    title: 'Extract Users',
    link: '/task',
    icon: <SearchOutlined />
  },
  {
    key: '4',
    title: 'All Processes',
    link: '/tasks',
    icon: <LineChartOutlined />
  },
  {
    key: "10",
    title: 'Similar Users',
    link: '/similar_users'
  },
  {
    key: "5",
    title: 'My Account',
    link: '/my-account',
    icon: <UserOutlined />,
  },
  {
    key: '6',
    title: 'Add Leads',
    link: '/add-leads',
    icon: <PlusOutlined />
  },
  {
    key: "8",
    title: 'Manage Leads',
    link: '/leads',
    icon: <SettingOutlined />
  },
  {
    key: "7",
    title: 'Manage Tasks',
    icon: <ApiOutlined />,
    link: '/manage-tasks'
  },
  {
    key: '13',
    title: 'Influencers',
    icon: <GroupOutlined />,
    link: '/influencers'
  },
  {
    key: '14',
    title: 'Influencers List',
    icon: <FileOutlined />,
    link: '/influencers-list'
  },
  {
    key: "9",
    title: 'Manage Data',
    link: '/manage-data',
    icon: <DatabaseOutlined />
  },
  {
    key: "2",
    title: 'FAQ',
    icon: <QuestionCircleOutlined />,
    link: '/faq'
  }
]

const Dashboard = ({user}) => {
  const [collapsed, toggleCollapsed] = useState(false)
  const [key, setKey] = useState("1")

  function toggle() {
    toggleCollapsed(!collapsed);
  }

  function toggleOnMobile() {
    if(isMobile()) {
      toggleCollapsed(true);
    }
  }

  function component() {
    switch (key) {
      case 1: return <HomePage user={user} setKey={setKey} />
      case 2: return <FAQ />
      default: return <HomePage user={user} setKey={setKey} />
    }
  }

  useEffect(()=>toggleOnMobile(), [])

  useEffect(() => {
    console.log(key, String(key))
  })

  return(
    <Layout className="is-fullheight">

      <Header 
        className="header has-background-white"
        style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%'
        }}
      >
        <img
          className="logo-img"
          alt="Logo"
          src={logo}
        />

        <Menu mode="horizontal" style={{float: 'right'}} >
          <Menu.Item key="3">
            <Button block onClick={()=>logout()} type="primary" className="dark-button">
              Log out
            </Button>
          </Menu.Item>
        </Menu>

      </Header>

      <Layout 
        className="site-layout"
        style={{
          marginTop: 64,
        }}
      >
        <Sider 
          collapsible 
          collapsed={collapsed} 
          onCollapse={toggle} 
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
        >
          <Menu 
            theme="dark" 
            selectedKeys={[key]} 
            mode="inline" 
            onClick={(key)=>setKey(parseInt(key.key))} 
            defaultSelectedKeys={['1']}
          >
            {MenuItems.map((item)=>(
              item.hide ? null :
              <Menu.Item key={item.key} icon={item.icon}>
                <Link to={item.link}>
                  {item.title}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <ContentWrapper
          marginLess
          plain
          style={{
            marginLeft: collapsed ? 100 : 200, 
            width: 'inherit'
          }}
        >
        <Switch>
          <Route
            path="/faq"
            exact
            component={() => (
              <FAQ setKey={()=>setKey("2")} />
            )}
          />

          <Route 
            path="/task"
            exact
            component={()=>(
              <ExtractUsers setKey={()=>setKey("3")} />
            )}
          />

          <Route 
            path="/add-leads"
            exact
            component={()=>(
              <AddLeads setKey={()=>setKey("6")} />
            )}
          />

          <Route 
            path="/leads"
            exact
            component={()=>(
              <ManageLeads setKey={()=>setKey("8")} />
            )}
          />

          <Route 
            path="/leads/:id"
            exact
            component={(props) => (
              <ViewEditLead setKey={()=>setKey("8")} {...props} />
            )}
          />

          <Route 
            path="/my-account"
            exact
            component={()=>(
              <MyAccount setKey={()=>setKey("5")} user={user} />
            )}
          />

          <Route 
            path="/tasks"
            exact
            component={()=>(
              <AllProcesses setKey={()=>setKey("4")} />
            )}
          />

          <Route 
            path="/manage-data"
            exact
            component={()=>(
              <ManageData setKey={()=>setKey("9")} />
            )}
          />

          <Route 
            path="/manage-tasks"
            exact
            component={() => (
              <ManageTasks setKey={()=>setKey("7")} />
            )}
          />

          <Route 
            path="/similar_users"
            exact
            component={() => (
              <SimilarUsers setKey={()=>setKey("10")} />
            )}
          />

          <Route 
            path="/influencers"
            exact
            render={() => {
              return <Influencers setKey={()=>setKey("13")} myKey={key} />
            }}
          />

          <Route 
            path="/influencers-list"
            exact
            component={() => (
              <InfluencerList setKey={()=>setKey('14')} myKey={key} />
            )}
          />

          <Route
            component={() => (
              <ViewUsers setKey={()=>setKey("1")} user={user} />
            )}
          />

        </Switch>
        </ContentWrapper>

      </Layout>

    </Layout>
  )
}

export default Dashboard