import {
  Button,
  Col,
  message,
  Pagination,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import ContentWrapper from "../../Components/ContentWrapper";
import { BadNotif } from "../../Common/Utils/SendNotification";
import {Link} from 'react-router-dom'
import GetTasks from '../../Common/ApiCall/GetTasks'
import GetOwners from '../../Common/ApiCall/GetOwners'
import moment from 'moment'
import TasksTableFilter from "./TasksTableFilter";
import ViewEditTask from "./ViewEditTask";
import AddTask from "./AddTask";
import UpdateTaskStatus from "../../Common/ApiCall/UpdateTaskStatus";
import CloseAllTasks from "../../Common/ApiCall/CloseAllTasks";
import GetCampaigns from "../../Common/ApiCall/GetCampaigns";

const { Title } = Typography;

const initialFields = {
  crm_owner_id: null,
  status: null,
  date: [],
  task_name_starts_with: null,
  no_activity_greater_than_days: null,
  ig_username: null,
  campaign_id: null
};

const PAGE_SIZE_OPTIONS = ["25", "50", "100"];
const DEFAULT_PAGE_SIZE = 100;

const dateFormat = "DD/MM/YYYY";

function momentObjectToEpoch(object) {
  return (object.valueOf())/1000
}

function epochToMoment(epoch) {
  return moment(epoch*1000)
}

function date(date_1, format = dateFormat) {
  const dateFormat1 = format;
  let date = new Date(date_1)
  return moment(date, dateFormat1)
}

/*
Task Object
id
crm_user_id
task_date
status: OPEN/CLOSED
crm_owner_id
title
description
ig_username
timestamps
*/

const TaskTables = ({ setKey }) => {
  const [load, setLoad] = useState(true);
  const [init, setInit] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [crm_owners, setCrmOwners] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalItems, setTotalItems] = useState(0);
  const [filters, setFilters] = useState(initialFields);
  const [initial, setInitial] = useState(true);
  const [taskId, setTaskId] = useState(null)
  const [taskObject, setTaskObject] = useState(null)
  const [editing, setEditing] = useState(false)
  const [add, setAdd] = useState(false)
  const [groups, setGroups] = useState<any>([])
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [campaigns, setCampaigns] = useState([])

  const showAdd = () => setAdd(true)
  const stopAdd = () => setAdd(false)

  const groupTasks = (items: any[], keyGetter) => {
    const map = new Map();
    items.forEach((item) => {
      const key = keyGetter(item)
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })
    return map;
  }

  const getIdsFromGroup = (group: any) => {
    let ids = []
    for (let task of group.tasks) {
      ids.push(task.id)
    }
    return ids
  }

  const closeAllTasks = (item: any) => {
    let user_ids = getIdsFromGroup(item)
    console.log(user_ids)
    message.warning("Processing...")
    CloseAllTasks(user_ids)
      .then(r => {
        if (r.success) {
          message.success("Tasks Closed")
          refresh()
        } else {
          BadNotif(r)
        }
      })
  }

  function groupBy(items) {
    const groupMap = groupTasks(items, i => (i.ig_username))
    const groupArray = mapToArray(groupMap)
    console.log(groupArray)
    setGroups(groupArray)
    return groupArray
  }

  function mapToArray(mapItems) {
    const groups = []
    for (let [k, v] of mapItems) {
      groups.push({
        username: k,
        tasks: v
      })
    }
    return groups
  }

  const getValueFromId = (array, index) => {
    for(let i=0;i<array.length;i++) {
      if(array[i]['id'] === index) {
        return array[i]['owner_name']
      }
    }
    return "NA"
  }

  const groupColumns = [
    {
      id: 'username',
      dataIndex: 'username',
      title: 'Ig Username'
    },
    {
      id: 'tasks',
      dataIndex: 'tasks',
      title: 'Tasks Count',
      render: i => i.length
    },
    {
      title: 'Actions',
      render: i => (
        <Button
          onClick={() => closeAllTasks(i)}
        >
          Close All Tasks
        </Button>
      )
    }
  ]

  const columns = [
    {
      id: 'name',
      dataIndex: 'title',
      title: 'Task Name'
    },
    {
      id: 'task_date',
      dataIndex: 'task_date',
      title: 'Task Date',
      render: i =>  date(i).format("DD/MM/YYYY HH:mm:ss")
    },
    {
      id: 'ig_username',
      dataIndex: 'ig_username',
      title: 'Username'
    },
    {
      id: 'status',
      dataIndex: 'status',
      title: 'Status'
    },
    {
      id: "crm_owner",
      dataIndex: "crm_owner_id",
      title: "Task Owner",
      render: i => getValueFromId(crm_owners, i)
    },
    {
      title: "Action",
      key: "action",
      render: (item) => (
        <Space size="middle">
          <Button
            onClick={() => {
              changeCrmTaskStatus(item.id, item.status==='OPEN' ? 'CLOSED' : 'OPEN')
            }}
          >
            {`Set Status ${item.status==='OPEN' ? "CLOSED" : "OPEN"}`}
          </Button>
          <Button
            href={"https://instagram.com/"+item.ig_username}
            target='_blank'
          >
            Open IG
          </Button>
          <Button
            type="primary"
            onClick={()=>editItem(item)}
          >
            View / Edit
          </Button>
          <Button>
            <Link
              to={{
                pathname: "/leads/" + item.crm_user_id
              }}
            >
              Visit User
            </Link>
          </Button>
          <Button>
          </Button>
        </Space>
      ),
    },
  ];

  const changeCrmTaskStatus = (task_id, status) => {
    setLoad(true)
    UpdateTaskStatus(task_id, status)
      .then(r => {
        setLoad(false)
        if (r.success) {
          message.success("Task Status Updated")
          getTasks(filters)
        } else {
          BadNotif(r, '')
        }
      })
  }

  const editItem = (item) => {
    setTaskId(item.id)
    setTaskObject(item)
    setEditing(true)
  }

  const backFromEdit = () => {
    setEditing(false)
    setTaskId(null)
    setTaskObject(null)
  }

  function getCrmOwners() {
    setInit(true)
    GetOwners()
      .then(r => {
        if(r.success) {
          setCrmOwners(r.data)
        }
      })
  }

  function getCampaigns() {
    setInit(true)
    GetCampaigns()
      .then(r => {
        setInit(false)
        if(r.success) {
          setCampaigns(r.data)
        }
      })
  }

  function filterBool(val) {
    let v = Object.fromEntries(
      Object.entries(val).filter(([_, v]) => v != null)
    );
    if(v.date!==undefined) {
      let a = null
      if(v.date[0]) {
        a = {
          start: momentObjectToEpoch(v.date[0].startOf("day")),
          end: momentObjectToEpoch(v.date[1].endOf("day"))
        }
      }
      delete v.date
      if(a!==null) {v.date = a}
    }
    return v
  }

  function getTasks(optValues: any = {page_size: pageSize, page_number: currentPage}, pg_size = pageSize, cp = currentPage) {
    setLoad(true)
    optValues.page_size = pg_size;
    optValues.page_number = cp;
    let values = filterBool(optValues);
    GetTasks(values).then((r) => {
      setLoad(false);
      if (r.success) {
        setTasks(r.data.tasks);
        groupBy(r.data.tasks)
        setTotalItems(r.data.count);
      } else {
        BadNotif(r)
      }
    });
  }

  function filterLeads(values) {
    setFilters(values);
    getTasks(values);
  }

  function changePage(page, size) {
    setCurrentPage(page);
    setPageSize(size);
    getTasks(filters, size, page);
  }

  function initalCalls() {
    getCrmOwners()
    getCampaigns()
  }

  const refresh = () => getTasks()

  const objectUpdate = (id) => {
    getTasks()
    backFromEdit()
  }

  useEffect(() => {
    setKey();
    initalCalls();
  }, []);

  return (
    <Row justify="center" gutter={24}>
      <Col md={{ span: 24 }} lg={{ span: 24 }}>
        <ContentWrapper marginLess>
          <Space direction="vertical">
            <Title level={3} style={{fontWeight: 'normal'}}>
              Tasks
            </Title>
          </Space>
          <Space direction="vertical" style={{ width: "100%" }}>
            <ViewEditTask 
              taskId={taskId}
              taskObject={taskObject}
              crmOwners={crm_owners}
              back={backFromEdit}
              refresh={refresh}
              editing={editing}
            />
            <TasksTableFilter
              init={init}
              filterLeads={filterLeads}
              filters={filters}
              initialFields={initialFields}
              initial={initial}
              setInital={() => setInitial(false)}
              crm_owners={crm_owners}
              campaigns={campaigns}
            />
            <div>
              <Space>
                <Title level={4}>Total {totalItems} Items</Title>
              </Space>
              <div className="menu-float-right">
                <Pagination
                  style={{
                    display: "inline-block",
                  }}
                  current={currentPage}
                  pageSize={pageSize}
                  pageSizeOptions={PAGE_SIZE_OPTIONS}
                  total={totalItems}
                  onChange={changePage}
                />
              </div>
            </div>
            <Table
              pagination={
                false && {
                  position: "topRight",
                  onChange: (page, size) => {
                    setCurrentPage(page);
                    setPageSize(size);
                  },
                  current: currentPage,
                  pageSize: pageSize,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  showSizeChanger: true,
                  total: totalItems,
                }
              }
              rowKey="username"
              expandable={{
                expandedRowRender: row => (
                  <Table
                    bordered
                    size="small"
                    loading={load}
                    columns={columns}
                    dataSource={row.tasks}
                    rowKey="id"
                  /> 
                ),
                expandedRowKeys: expandedRowKeys,
                onExpand: (expanded, record) => {
                  if (expanded) {
                    setExpandedRowKeys([ record.username ,...expandedRowKeys])
                  } else {
                    const indi = expandedRowKeys.indexOf(record.username)
                    if (indi > -1) {
                      expandedRowKeys.splice(indi, 1)
                    }
                  }
                }
              }}
              bordered
              loading={load}
              columns={groupColumns}
              dataSource={groups}
            />
{/*             <Table
              pagination={
                false && {
                  position: "topRight",
                  onChange: (page, size) => {
                    setCurrentPage(page);
                    setPageSize(size);
                  },
                  current: currentPage,
                  pageSize: pageSize,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  showSizeChanger: true,
                  total: totalItems,
                }
              }
              bordered
              size="small"
              loading={load}
              columns={columns}
              dataSource={tasks}
            /> */}
          </Space>
        </ContentWrapper>
      </Col>
    </Row>
  );
};

export default TaskTables;
