import React, { useEffect, useState } from 'react'
import ContentWrapper from '../../Components/ContentWrapper'
import {
  message,
  Table,
  Typography,
  Divider,
  Input,
  Space,
  Button,
  Row,
  Col,
  Avatar
} from 'antd'
import GetIgDataFromUsername from '../../Common/ApiCall/GetIgDataFromUsername'
import ApiError from '../../Common/Models/ApiError'
import { BadNotif } from '../../Common/Utils/SendNotification'
import GetSimilarAccounts from '../../Common/ApiCall/GetSimilarAccounts'
import { UserOutlined } from '@ant-design/icons'

const {Title} = Typography

const SimilarUsers = ({
  setKey
}: any) => {
  const [items, setItems] = useState([])
  const [load, setLoad] = useState(false)
  const [itemsLoad, setItemsLoad] = useState(false)
  const [userObj, setUserObj] = useState(null)
  const [username, setUsername] = useState('')

  useEffect(() => {
    setKey()
  }, [])

  async function makeDetailedUserFromUsername(q) {
    setLoad(true)
    let userData = await GetIgDataFromUsername(q);
    /* let userData = {follower_count: 137884825,
      following_count: 139,
      id: "13460080",
      name: "Nike",
      picture: "https://instagram.fmnl4-3.fna.fbcdn.net/v/t51.2885-19/s150x150/26155970_1584552474997482_4541081815552622592_n.jpg?tp=1&_nc_ht=instagram.fmnl4-3.fna.fbcdn.net&_nc_ohc=fUOCZZsGpfEAX-8Haas&oh=13d10ba9173aa9cde3b871665f7dc4d5&oe=60723FCE",
      username: "nike"} */
    setLoad(false)
    if(userData instanceof ApiError) {
      if(userData.message === "User does not exist") {
        BadNotif(null, "Instagram Username does not exist")
      } else {
        BadNotif(null, "Something Went Wrong")
      }
    } else {
      if (userData.biography === "COULD_NOT_ACCESS_THIS_USER") {
        message.error("Please check the username entered and try again")
        return
      }
      setUserObj(userData)
      getSimilarAccounts(userData.id)
    }
  }

  const getSimilarAccounts = (ig_id: string) => {
    setItemsLoad(true)
    GetSimilarAccounts(ig_id)
      .then(r => {
        setItemsLoad(false)
        if (r.success) {
          setItems(r.data)
        } else {
          BadNotif(r)
        }
      })
  }

  const getItems = () => {
    makeDetailedUserFromUsername(username)
  }

  const columns = [
    {
      key: 'image',
      title: 'Image',
      dataIndex: 'profile_pic_url',
      render: i => (
        <Avatar
          src={i}
          size={50}
          shape='circle'
        />
      )
    },
    {
      id: 'username',
      dataIndex: 'username',
      title: 'Username'
    },
    {
      key: 'full_name',
      dataIndex: 'full_name',
      title: 'Full Name'
    },
    {
      key: 'Open Profile',
      render: i => (
        <Space>
          <Button
            href={'https://instagram.com/' + i.username}
            target='_blank'
          >
            Open Profile
          </Button>
        </Space>
      )
    }
  ]

  return (
    <Row gutter={24}>
      <Col span={22}>
      <ContentWrapper>
        <Title level={3}>
          Similar Accounts
        </Title>
        <Divider />
        {
            userObj ? (
              <>
                <Space
                  direction='vertical'
                  style={{
                    width: '100%'
                  }}
                >
                  <Title level={4}>
                    Similar Users with {userObj.username} discovered: {items.length}
                </Title>
                <Table
                  loading={itemsLoad}
                  dataSource={items}
                  columns={columns}
                />
                </Space>
                </>
            ) : (
                <>
                  <Space direction='vertical'>
                  <Input.Search
                    placeholder="Enter Instagram Username here"
                    size='large'
                    style={{
                      maxWidth: 700
                      }}
                    onChange={e => setUsername(e.target.value)}
                      loading={load}
                      onPressEnter={getItems}
                      onSearch={getItems}
                    />
                    <p>
                      {
                        load ? (
                          "Getting Instagram User Info..."
                        ) : (
                            itemsLoad ? (
                              "Getting Similar Accounts..."
                          ) : null
                        )
                      }
                    </p>
                  </Space>
                </>
          )
        }
      </ContentWrapper>
      </Col>
    </Row>
  )
}

export default SimilarUsers