import React, { useEffect, useState } from 'react'
import {Button, message} from 'antd'
import ShowArea from './ShowArea'
import AddAreaModal from './AddAreaModal'
import GetAreas from '../../../Common/ApiCall/GetAreas'
import AddArea from '../../../Common/ApiCall/AddArea'
import { BadNotif } from '../../../Common/Utils/SendNotification'

const AreaPanel = () => {
  const [data, setData] = useState([])
  const [load, setLoad] = useState(true)
  const [vis, setVis] = useState(false)

  const openModal = () => setVis(true)
  const closeModal = () => setVis(false)

  const getAreas = () => {
    setLoad(true)
    GetAreas()
      .then(r => {
        setLoad(false)
        if(r.success) {
          setData(r.data)
        }
      })
  }

  const addArea = (q) => {
    if(q.length<1) {
      message.warn("Enter Value to Continue")
      return
    }
    setLoad(true)
    AddArea(q)
      .then(r => {
        setLoad(false)
        if(r.success) {
          message.success("Added Area Successfully.")
          closeModal()
          getAreas()
        } else {
          BadNotif(r)
        }
      })
  }
  
  useEffect(()=>{
    getAreas()
  }, [])

  return(
    <>
      <Button
        type="primary"
        onClick={openModal}
      >
        Add New Area Item
      </Button>
      <ShowArea 
        data={data}
        load={load}
      />
      <AddAreaModal
        visible={vis}
        addArea={addArea}
        closeModal={closeModal}
      />  
    </>
  )
}

export default AreaPanel