import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from './auth-header'

const DownloadUsersInfluencerList = async (
  influencer_list_id: number
) => {
  let apiResponse;
  if ( RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/influencer_list/download"), {
      influencer_list_id
    }, 
    authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
    data: [
      "Montreal",
      "Boston",
      "Out of Zone",
      "Toronto"
    ],
    success: true,
    errors: []
  }
}

export default DownloadUsersInfluencerList
