import { GetRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from './auth-header'

const GetInfluencers = async (values) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/influencers"), 
    values
    , authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {

  let a= {
    username: "abhnv_rai",
    full_name: "Abhinav Rai",
    bio_email: "bioemail@gmail.com", 
    public_email: "public@mail.com", 
    email: 'mail@mail.com',
    phone: "98787878", 
    city: "Delhi", 
    follower_count: 987, 
    following_count: 123, 
    post_count: 23, 
    engagement_rate: 45.3, 
    is_business_account: true, 
    website: 'website.com', 
    bio: 'This is a bio. It is long', 
    category: [
      {id: 12, name: "follower of abhnv_rai"},
      {id: 13, name: 'following of abhnv_rai'}
    ], 
    ig_id: '123igid'
  }
  let b = [a, a, a, a, a, a, a, a ,a ,a ,a , a]

  return {
    data: {
      count: 4373,
      users: b
    },
    success: true,
    errors: []
  }
}

export default GetInfluencers
