import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from './auth-header'

const GetCampaigns = async () => {
  let apiResponse;
  if ( RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/crm/campaigns"), {}, 
    authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
    data: [
      {
        id: 1,
        name: 'Followers of @abhnv'
      },
      {
        id: 2,
        name: 'Following of @_a_gryffindor'
      }
    ],
    success: true,
    errors: []
  }
}

export default GetCampaigns
