import { Button, Card, Checkbox, Col, Collapse, Input, InputNumber, Row, Select, Space, Spin, Switch } from 'antd'
import {Form} from 'antd'
import React, { useEffect } from 'react'
import Loader from '../../Components/Loader'

const bools = [
  {
    value: 'true',
    option: 'Yes'
  },
  {
    value: 'false',
    option: 'No'
  }
]

const {Panel} = Collapse

const ViewUserFilter = ({categories, init, filterUsers, filters, initialFields, initial, setInital}) => {
  const [form] = Form.useForm()

  function submit(val = form.getFieldsValue()) {
    filterUsers(val)
  }

  function reset() {
    form.resetFields()
  }

  function clear() {
    filterUsers(initialFields)
    form.setFieldsValue(initialFields)
  }

  useEffect(()=>{
    if(initial && form.getFieldValue('category_id') !== filters.category_id) {
      form.setFieldsValue({
        category_id: filters.category_id
      })
      submit({category_id: filters.category_id})
    }
  })

  if(init) {
    return <Loader />
  }

  return(
    <Card>
      <Form
        form={form}
        layout="horizantal"
        initialValues={filters}
        onFieldsChange={v=>console.log(v)}
        onFinish={filterUsers}
        hideRequiredMark
      >
            <Form.Item
              label="Category"
              name="category_id"
              rules={[{required: true}]}
            >
              <Select
                placeholder="Select a Category"
                style={{maxWidth: '400px'}}
                onChange={e=>submit({...filters, category_id: e})}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => 
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {categories.map((category) => (
                  <Select.Option
                    value={category.id}
                    key={category.id}
                    title={category.name}
                  >
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
        <Collapse
          style={{backgroundColor: 'black !important'}}
        >
          <Panel
            style={{color: 'black !important'}}
            header="Advanced Filters"
          >
          <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="followers_greater_than"
              label="Followers Greater Than"
            >
              <InputNumber 
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="followers_less_than"
              label="Followers Less Than"
            >
              <InputNumber 
                min={0}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Is Email There" 
              name="is_email_there"
            >
              <Select style={{maxWidth: '100px'}}>
                {bools.map((bool) => (
                  <Select.Option key={bool.option} value={bool.value}>
                    {bool.option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Is Phone Number There"
              name="is_contact_there"
            >
              <Select style={{maxWidth: '100px'}}>
                {bools.map((bool) => (
                  <Select.Option  key={bool.option} value={bool.value}>
                    {bool.option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Is Website There"
              name="is_website_there"
            >
              <Select style={{maxWidth: '100px'}}>
                {bools.map((bool) => (
                  <Select.Option key={bool.option} value={bool.value}>
                    {bool.option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} >
        <Col span={8}>
            <Form.Item
              label="Is Business Account"
              name="is_business_account"
            >
              <Select style={{maxWidth: '100px'}}>
                {bools.map((bool) => (
                  <Select.Option value={bool.value} key={bool.option}>
                    {bool.option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="phone_number_starts_with"
              label="Phone Number Starts With"
            >
              <Input 
                style={{maxWidth: '100px'}}
              />
            </Form.Item>   
          </Col>
          <Col span={8}>
            <Form.Item
              name="search_location"
              label="Search Location"
            >
              <Input />
            </Form.Item>   
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="posts_greater_than"
              label="Posts Greater Than"
            >
              <InputNumber 
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="posts_less_than"
              label="Posts Less Than"
            >
              <InputNumber 
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              shouldUpdate
            >
              {()=> (
                <Space>
                  <Button 
                    type="primary" 
                    onClick={()=>submit()}
                    disabled={!form.isFieldsTouched()}
                  >
                    Update Filters
                  </Button>
                  <Button type="dashed" onClick={clear}>
                    Clear Filters
                  </Button>
                </Space>
              )}
            </Form.Item>
          </Col>
        </Row>
                  </Panel>
            </Collapse>
      </Form>
    </Card>
  )
}

export default ViewUserFilter