import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from './auth-header'

const UpdateTask = async (task_id, task_date, status, title, owner_id, description) => {
  let apiResponse;
  if ( RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/crm/task/update"), {
      task_id, task_date, status, title, owner_id, description
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
    data: {
      "count": 120,
      "tasks": [
        {
          "id": 1,
          "crm_user_id": 10,
          "task_date": "2021-04-10 20:46:59",
          "status": "OPEN", // or "CLOSED",
          "owner_id": 12,
          "name": "Like the post for the user",
          "description": "Here is the link: lorem ipsum",
          "ig_username": "_a_gryffndor",
          "created_at": "2021-04-10 20:46:59",
          "updated_at": "2021-04-10 20:46:59"
        },
      ]
    }
  ,
    success: true,
    errors: []
  }
}

export default UpdateTask
