import React from "react";
import {Layout} from "antd";

const {Content} = Layout

type props = {
  children: any,
  marginLess?: boolean,
  style?: React.CSSProperties,
  plain?: boolean
}

const ContentWrapper = ({
  children, 
  marginLess, 
  style, 
  plain = false
}: props) => {
  return(
    <Content
      style={style}
      className={`${plain ? "" : "site-layout-background"} content-wrapper ` + (marginLess ? 'content-wrapper-margin-less' : '')}
    >
      {children}
    </Content>
  )
}

export default ContentWrapper