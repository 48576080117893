import {
    Form,
    Input,
    message,
    Select,
    DatePicker,
    Modal,
    InputNumber,
    Button,
    Skeleton,
} from "antd";
import React, { useEffect, useState } from "react";
import { BadNotif } from "../../Common/Utils/SendNotification";
import moment from "moment";
import UpdateTask from "../../Common/ApiCall/UpdateTask";
import TaskEnums from "./TasksEnums";

const {Option } = Select

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};
const formTailLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12, offset: 6 },
};

const rowProps = {
    gutter: 24,
};

const colProps = {
    md: { span: 11, offset: 1 },
    lg: { span: 7, offset: 1 },
};

const dateFormat = "DD/MM/YYYY";

function date(date_1) {
    let date = new Date(date_1);
    return moment(date, dateFormat);
}

function datePrintable(d) {
    return moment(d).format(dateFormat);
}

function momentObjectToEpoch(object) {
    return object.valueOf() / 1000;
}

/*
Task Object
id
crm_user_id
task_date
status: OPEN/CLOSED
crm_owner_id
title
description
ig_username
timestamps
*/

const ViewEditTask = ({
    taskId,
    taskObject,
    crmOwners,
    back,
    refresh,
    editing
}) => {
    const [load, setLoad] = useState(false);
    const [taskForm] = Form.useForm();

    const changeUserFormat = (user) => {
        user.lead_status_change_date = date(user.lead_status_change_date);
        user.created_at = date(user.created_at);
        user.next_step_date = date(user.next_step_date);
        return user;
    };

    const commonUpdate = (values) => {
        let editableFields = {
            instagram_account_owner: values.instagram_account_owner,
            first_name: values.first_name,
            last_name: values.last_name,
            area_id: values.area_id,
        };
        return editableFields;
    };

    function updateTask(values) {
        setLoad(true);
        UpdateTask(
            taskId,
            momentObjectToEpoch(values.task_date),
            values.status,
            values.title,
            values.crm_owner_id,
            values.description
        ).then((r) => {
            setLoad(false);
            if (r.success) {
                message.success("Task Details Updated");
                refresh()
            } else {
                BadNotif(r);
            }
        });
    }

    function clear() {
        taskForm.setFieldsValue(taskObject);
    }

    function emptyDate() {
        const dateFormat = "YYYY/MM/DD";
        let date = new Date(null);
        return moment(date, dateFormat);
    }

    function renderDate(epoch) {
        let d = new Date(0);
        d.setUTCSeconds(epoch);
        return d.toISOString().split("T")[0];
    }

    useEffect(()=>{
      if(editing) {
        taskForm.setFieldsValue({
          ...taskObject,
          task_date: date(taskObject.task_date),
          created_at: date(taskObject.created_at)
        })
      }
    }, [editing])

    if(!editing) {
      return null
    }

    return (
        <Modal
            visible={editing}
            width={800}
            footer={null}
            onCancel={back}
            title="View / Edit Task"
        >
                <Form
                    form={taskForm}
                    hideRequiredMark
                    onFinish={updateTask}
                >
                    <Form.Item 
                      name="title" 
                      label="Title"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Owner"
                      name="crm_owner_id"
                      rules={[{ required: true }]}
                    >
                        <Select style={{ maxWidth: "400px" }} disabled>
                            {crmOwners.map((item) => (
                                <Select.Option
                                    value={item.id}
                                    key={item.id}
                                    title={item.owner_name}
                                >
                                    {item.owner_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                      label="User Id"
                      name="crm_user_id"
                      rules={[{type: 'number'}]}
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item 
                      label="Ig Username" 
                      name="ig_username"
                    >
                      <Input 
                        disabled
                      />
                    </Form.Item>
                    <Form.Item 
                      name="status"
                      label="Status"
                    >
                      <Select
                        placeholder="Select a Status"
                        style={{ maxWidth: "400px" }}
                      >
                        {TaskEnums.STATUS.map((item) => (
                          <Option 
                            value={item} 
                            key={item} 
                            title={item}
                          >
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item 
                      name="task_date" 
                      label="Task Date"
                    >
                      <DatePicker format={dateFormat} />
                    </Form.Item>
                    <Form.Item 
                      name="created_at" 
                      label="Created At"
                    >
                        <DatePicker disabled format={dateFormat} />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                      shouldUpdate
                    >
                      {() => (
                        <Button
                          type='primary'
                          htmlType='submit'
                        >
                          Update
                        </Button>
                      )}
                    </Form.Item>
                </Form>
        </Modal>
    );
};

export default ViewEditTask;
