import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from './auth-header'

const GetTasks = async (values) => {
  let apiResponse;
  if ( RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/crm/tasks"), 
      values
    , authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
      data: {
          count: 139,
          tasks: [
              {
                  id: 4,
                  crm_user_id: 166,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:01:51.315Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:01:51.328Z",
                  updated_at: "2021-05-23T12:01:51.328Z",
                  ig_username: "aapowertoolrepair",
              },
              {
                  id: 5,
                  crm_user_id: 480,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:01:51.315Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:01:51.328Z",
                  updated_at: "2021-05-23T12:01:51.328Z",
                  ig_username: "brantcarpentry",
              },
              {
                  id: 6,
                  crm_user_id: 536,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:01:55.888Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:01:55.888Z",
                  updated_at: "2021-05-23T12:01:55.888Z",
                  ig_username: "robertcrippsconstruction",
              },
              {
                  id: 8,
                  crm_user_id: 128,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:01:59.662Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:01:59.662Z",
                  updated_at: "2021-05-23T12:01:59.662Z",
                  ig_username: "jljconcepts",
              },
              {
                  id: 7,
                  crm_user_id: 108,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:01:59.662Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:01:59.662Z",
                  updated_at: "2021-05-23T12:01:59.662Z",
                  ig_username: "emanuelcarter29",
              },
              {
                  id: 9,
                  crm_user_id: 131,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:01:59.662Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:01:59.662Z",
                  updated_at: "2021-05-23T12:01:59.662Z",
                  ig_username: "mikethecarpenter.rmg",
              },
              {
                  id: 13,
                  crm_user_id: 123,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:02:04.432Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:02:04.432Z",
                  updated_at: "2021-05-23T12:02:04.432Z",
                  ig_username: "gatti_brothers",
              },
              {
                  id: 12,
                  crm_user_id: 242,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:02:04.432Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:02:04.432Z",
                  updated_at: "2021-05-23T12:02:04.432Z",
                  ig_username: "dm.drywallfinishing",
              },
              {
                  id: 14,
                  crm_user_id: 256,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:02:08.316Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:02:08.316Z",
                  updated_at: "2021-05-23T12:02:08.316Z",
                  ig_username: "firstplace_homes",
              },
              {
                  id: 22,
                  crm_user_id: 99,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:04:00.658Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:04:00.659Z",
                  updated_at: "2021-05-23T12:04:00.659Z",
                  ig_username: "kilbarryhillconstructionltd",
              },
              {
                  id: 24,
                  crm_user_id: 557,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:04:00.658Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:04:00.659Z",
                  updated_at: "2021-05-23T12:04:00.659Z",
                  ig_username: "ms7construction",
              },
              {
                  id: 23,
                  crm_user_id: 356,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:04:00.658Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:04:00.659Z",
                  updated_at: "2021-05-23T12:04:00.659Z",
                  ig_username: "renovation_canada_toronto",
              },
              {
                  id: 25,
                  crm_user_id: 340,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:04:04.451Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:04:04.451Z",
                  updated_at: "2021-05-23T12:04:04.451Z",
                  ig_username: "mahzadhomes",
              },
              {
                  id: 26,
                  crm_user_id: 63,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:04:04.451Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:04:04.451Z",
                  updated_at: "2021-05-23T12:04:04.451Z",
                  ig_username: "skylarkhomes",
              },
              {
                  id: 29,
                  crm_user_id: 465,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:04:09.419Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:04:09.419Z",
                  updated_at: "2021-05-23T12:04:09.419Z",
                  ig_username: "buildmakerenovations",
              },
              {
                  id: 27,
                  crm_user_id: 378,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:04:09.419Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:04:09.419Z",
                  updated_at: "2021-05-23T12:04:09.419Z",
                  ig_username: "vpv_contracting",
              },
              {
                  id: 28,
                  crm_user_id: 404,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:04:09.419Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:04:09.419Z",
                  updated_at: "2021-05-23T12:04:09.419Z",
                  ig_username: "easydrywall",
              },
              {
                  id: 30,
                  crm_user_id: 362,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:04:09.419Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:04:09.419Z",
                  updated_at: "2021-05-23T12:04:09.419Z",
                  ig_username: "woodbully",
              },
              {
                  id: 31,
                  crm_user_id: 385,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:04:12.884Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:04:12.885Z",
                  updated_at: "2021-05-23T12:04:12.885Z",
                  ig_username: "canera_homes_inc",
              },
              {
                  id: 32,
                  crm_user_id: 388,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:04:12.885Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:04:12.885Z",
                  updated_at: "2021-05-23T12:04:12.885Z",
                  ig_username: "argcongroup",
              },
              {
                  id: 46,
                  crm_user_id: 511,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:04:38.317Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:04:38.317Z",
                  updated_at: "2021-05-23T12:04:38.317Z",
                  ig_username: "cerque_developments",
              },
              {
                  id: 44,
                  crm_user_id: 487,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:04:38.317Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:04:38.317Z",
                  updated_at: "2021-05-23T12:04:38.317Z",
                  ig_username: "harveyhomesandinteriors",
              },
              {
                  id: 45,
                  crm_user_id: 415,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:04:38.317Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:04:38.317Z",
                  updated_at: "2021-05-23T12:04:38.317Z",
                  ig_username: "davis.renovations",
              },
              {
                  id: 54,
                  crm_user_id: 37,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:05:34.693Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:05:34.694Z",
                  updated_at: "2021-05-23T12:05:34.694Z",
                  ig_username: "innovativecrownconstruction",
              },
              {
                  id: 56,
                  crm_user_id: 408,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:05:34.694Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:05:34.694Z",
                  updated_at: "2021-05-23T12:05:34.694Z",
                  ig_username: "serendipityhomesinc",
              },
              {
                  id: 57,
                  crm_user_id: 375,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:05:37.413Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:05:37.413Z",
                  updated_at: "2021-05-23T12:05:37.413Z",
                  ig_username: "cactus_landscaping",
              },
              {
                  id: 61,
                  crm_user_id: 161,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:05:42.694Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:05:42.694Z",
                  updated_at: "2021-05-23T12:05:42.694Z",
                  ig_username: "handcraftedbychrispalmer",
              },
              {
                  id: 59,
                  crm_user_id: 420,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:05:42.694Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:05:42.694Z",
                  updated_at: "2021-05-23T12:05:42.694Z",
                  ig_username: "m.dhardwoodinstallation",
              },
              {
                  id: 60,
                  crm_user_id: 425,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:05:42.694Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:05:42.694Z",
                  updated_at: "2021-05-23T12:05:42.694Z",
                  ig_username: "mutikb",
              },
              {
                  id: 58,
                  crm_user_id: 421,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:05:42.694Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:05:42.694Z",
                  updated_at: "2021-05-23T12:05:42.694Z",
                  ig_username: "senso_group",
              },
              {
                  id: 65,
                  crm_user_id: 590,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:05:45.412Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:05:45.413Z",
                  updated_at: "2021-05-23T12:05:45.413Z",
                  ig_username: "garagedoorsplusma",
              },
              {
                  id: 63,
                  crm_user_id: 93,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:05:45.412Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:05:45.413Z",
                  updated_at: "2021-05-23T12:05:45.413Z",
                  ig_username: "iohimprovement",
              },
              {
                  id: 64,
                  crm_user_id: 253,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:05:45.412Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:05:45.413Z",
                  updated_at: "2021-05-23T12:05:45.413Z",
                  ig_username: "mazengabuilt",
              },
              {
                  id: 62,
                  crm_user_id: 555,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:05:45.412Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:05:45.413Z",
                  updated_at: "2021-05-23T12:05:45.413Z",
                  ig_username: "prbrenos",
              },
              {
                  id: 67,
                  crm_user_id: 572,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:05:55.415Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:05:55.416Z",
                  updated_at: "2021-05-23T12:05:55.416Z",
                  ig_username: "thekingdomgroup",
              },
              {
                  id: 66,
                  crm_user_id: 562,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:05:55.415Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:05:55.416Z",
                  updated_at: "2021-05-23T12:05:55.416Z",
                  ig_username: "renovationcollectivetoronto",
              },
              {
                  id: 69,
                  crm_user_id: 94,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:05:58.412Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:05:58.412Z",
                  updated_at: "2021-05-23T12:05:58.412Z",
                  ig_username: "avviofinehomes",
              },
              {
                  id: 89,
                  crm_user_id: 494,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:06:36.135Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:06:36.135Z",
                  updated_at: "2021-05-23T12:06:36.135Z",
                  ig_username: "heavydutyhomes",
              },
              {
                  id: 88,
                  crm_user_id: 501,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:06:36.135Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:06:36.135Z",
                  updated_at: "2021-05-23T12:06:36.135Z",
                  ig_username: "gta_railings",
              },
              {
                  id: 90,
                  crm_user_id: 312,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:06:53.316Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:06:53.317Z",
                  updated_at: "2021-05-23T12:06:53.317Z",
                  ig_username: "juniper_wood_working_inc",
              },
              {
                  id: 93,
                  crm_user_id: 532,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:06:56.411Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:06:56.412Z",
                  updated_at: "2021-05-23T12:06:56.412Z",
                  ig_username: "pacbuildinggroup",
              },
              {
                  id: 92,
                  crm_user_id: 237,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:06:56.411Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:06:56.412Z",
                  updated_at: "2021-05-23T12:06:56.412Z",
                  ig_username: "mahoganytrimcarpentry",
              },
              {
                  id: 94,
                  crm_user_id: 549,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:06:56.411Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:06:56.412Z",
                  updated_at: "2021-05-23T12:06:56.412Z",
                  ig_username: "rvc_homes",
              },
              {
                  id: 95,
                  crm_user_id: 560,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:07:00.315Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:07:00.315Z",
                  updated_at: "2021-05-23T12:07:00.315Z",
                  ig_username: "mtmgeneralcontracting",
              },
              {
                  id: 97,
                  crm_user_id: 606,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:07:04.411Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:07:04.411Z",
                  updated_at: "2021-05-23T12:07:04.411Z",
                  ig_username: "boydbuilders.inc",
              },
              {
                  id: 125,
                  crm_user_id: 288,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:06:07.001Z",
                  status: "OPEN",
                  description: "Posts Before 321 | After 322",
                  title: "New Post Added",
                  created_at: "2021-05-23T12:08:31.337Z",
                  updated_at: "2021-05-23T12:08:31.337Z",
                  ig_username: "mgbconstruct",
              },
              {
                  id: 111,
                  crm_user_id: 288,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:06:07.001Z",
                  status: "OPEN",
                  description: "Posts Before 321 | After 322",
                  title: "New Post Added",
                  created_at: "2021-05-23T12:07:49.840Z",
                  updated_at: "2021-05-23T12:07:49.840Z",
                  ig_username: "mgbconstruct",
              },
              {
                  id: 86,
                  crm_user_id: 288,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:06:07.001Z",
                  status: "OPEN",
                  description: "Posts Before 321 | After 322",
                  title: "New Post Added",
                  created_at: "2021-05-23T12:06:26.379Z",
                  updated_at: "2021-05-23T12:06:26.379Z",
                  ig_username: "mgbconstruct",
              },
              {
                  id: 77,
                  crm_user_id: 288,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:06:07.001Z",
                  status: "OPEN",
                  description: "Posts Before 321 | After 322",
                  title: "New Post Added",
                  created_at: "2021-05-23T12:06:07.037Z",
                  updated_at: "2021-05-23T12:06:07.037Z",
                  ig_username: "mgbconstruct",
              },
              {
                  id: 99,
                  crm_user_id: 299,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:07:08.316Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:07:08.316Z",
                  updated_at: "2021-05-23T12:07:08.316Z",
                  ig_username: "marcheselandscaping",
              },
          ],
      },
      success: true,
      error: null,
  };
}

export default GetTasks
