import { Button, Card, Col, Collapse, Input, InputNumber, Row, Select, Space, Spin, Switch } from 'antd'
import {Form} from 'antd'
import React, { useEffect } from 'react'
import Loader from '../../Components/Loader'

const bools = [
  {
    value: 'true',
    option: 'Yes'
  },
  {
    value: 'false',
    option: 'No'
  }
]

const {Panel} = Collapse

type InfluencersFilterProps = {
  init: Boolean,
  filterUsers: Function,
  filters: any,
  initialFields: any,
  categories: any
}

const InfluencersFilter = ({
  init, 
  filterUsers, 
  filters, 
  initialFields,
  categories
}: InfluencersFilterProps) => {
  const [form] = Form.useForm()

  function submit(val = form.getFieldsValue()) {
    filterUsers(val)
  }

  function reset() {
    form.resetFields()
  }

  function clear() {
    filterUsers(initialFields)
    form.setFieldsValue(initialFields)
  }

  if(init) {
    return <Loader />
  }

  return(
    <Card>
      <Form
        form={form}
        layout='horizontal'
        initialValues={filters}
        //onFieldsChange={v=>console.log(v)}
        onFinish={v => filterUsers(v)}
        hideRequiredMark
      >
        <Form.Item
          name="only_zak"
          label="Only Zak"
          valuePropName="checked"
        >
          <Switch 
            onChange={e => submit({...form.getFieldsValue(), only_zak: e})}
          />
        </Form.Item>
        <Collapse
          style={{backgroundColor: 'black !important'}}
        >
          <Panel
            key='naya'
            style={{color: 'black !important'}}
            header="Advanced Filters"
          >
          <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="followers_greater_than"
              label="Followers Greater Than"
            >
              <InputNumber 
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="followers_less_than"
              label="Followers Less Than"
            >
              <InputNumber 
                min={0}
              />
            </Form.Item>
              </Col>
              <Col span={8}>
              <Form.Item
                label="Category"
                name="business_category_name_starts_with"
                rules={[{required: true}]}
              >
                  <Select
                    placeholder="Select a Category"
                    style={{ maxWidth: '400px' }}
                    onChange={e => {
                      //console.log(e)
                      submit({ ...filters, business_category_name_starts_with: e })
                    }}
                    showSearch
                    optionFilterProp="children"
/*                     filterOption={(input, option) =>
                      option.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    } */
                >
                  {categories.map((category) => (
                    <Select.Option
                      value={category}
                      key={category}
                      title={category}
                    >
                      {category}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Is Email There" 
              name="is_email_there"
            >
              <Select style={{maxWidth: '100px'}}>
                {bools.map((bool) => (
                  <Select.Option key={bool.option} value={bool.value}>
                    {bool.option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
              </Col>
              <Col span={8}>
            <Form.Item
              name="following_greater_than"
              label="Following Greater Than"
            >
              <InputNumber 
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="following_less_than"
              label="Following Less Than"
            >
              <InputNumber 
                min={0}
              />
            </Form.Item>
              </Col>
        </Row>
        <Row gutter={24} >
          <Col span={8}>
            <Form.Item
              name="phone_number_starts_with"
              label="Phone Number Starts With"
            >
              <Input 
                style={{maxWidth: '100px'}}
              />
            </Form.Item>   
              </Col>
              <Col span={8}>
            <Form.Item
              name="engagement_rate_greater_than"
              label="Engagement Rate Greater Than"
            >
              <InputNumber 
                    min={0}
                    max={100}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="engagement_rate_less_than"
              label="Engagement Rate Less Than"
            >
              <InputNumber 
                min={0}
              />
            </Form.Item>
              </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="posts_greater_than"
              label="Posts Greater Than"
            >
              <InputNumber 
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="posts_less_than"
              label="Posts Less Than"
            >
              <InputNumber 
                min={0}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              shouldUpdate
            >
              {()=> (
                <Space>
                  <Button 
                    type="primary" 
                    onClick={()=>submit()}
                    disabled={!form.isFieldsTouched()}
                  >
                    Update Filters
                  </Button>
                  <Button type="dashed" onClick={() => clear()}>
                    Clear Filters
                  </Button>
                </Space>
              )}
            </Form.Item>
          </Col>
        </Row>
                  </Panel>
            </Collapse>
      </Form>
    </Card>
  )
}

export default InfluencersFilter