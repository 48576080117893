import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from './auth-header'

const GetCRMUserDetails = async (user_id) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/crm/user/"+user_id), {}
    , authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {

  return {
    data: {
      id: 23,
      user_id: 2,
      campaign_id: 7,
      instagram_account_owner: "awdmkawmd",
      first_name: "",
      last_name: "",
      area_id: 2,
      lead_status_id: 4,
      lead_status_change_date: null,
      next_step_date: null,
      step_date_comments: [],
      hubspot_owner: "",
      contractor_type: "awmdkam",
      is_tracking: true,
      post_count: 0,
      igtv_count: 0,
      website: null,
      phone_number: "987678766",
      email: "",
      ig_id: "2",
      bio: "amawdkmwakmaw",
      username: "test",
      category: null,
      created_at: "2021-04-13T13:24:30.287Z",
      updated_at: "2021-04-14T07:59:25.603Z",
      tracking_changes: [
        { date: "2021-04-10 21:58:56", change_type: "POST", "before": '12', after: 13 },
        { date: "2021-03-10 21:58:56", change_type: "IGTV", "before": '2', after: 3 },
        { date: "2021-04-10 21:58:56", change_type: "STORY"}
      ],
    },
    success: true,
    error: null,
  };
}

export default GetCRMUserDetails
