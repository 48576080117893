import React, { useEffect, useState } from 'react'
import {Button, message, Typography} from 'antd'
import AddLeadStatusModal from './AddLeadStatusModal'
import AddLeadStatus from '../../../Common/ApiCall/AddLeadStatus'
import ShowLeadStatus from './ShowLeadStatus'
import GetLeadStatuses from '../../../Common/ApiCall/GetLeadStatuses'
import { BadNotif } from '../../../Common/Utils/SendNotification'

const LeadStatus = () => {
  const [data, setData] = useState([])
  const [load, setLoad] = useState(true)
  const [vis, setVis] = useState(false)

  const closeModal = () => setVis(false)
  const openModal = () => setVis(true)

  function getData() {
    setLoad(true)
    GetLeadStatuses()
      .then(r => {
        setLoad(false)
        if(r.success) {
          setData(r.data)
        }
      })
  }

  function addData(q) {
    if(q.length<1) {
      message.warn("Enter Value to Continue")
      return
    }
    setLoad(true)
    AddLeadStatus(q)
      .then(r => {
        setLoad(false)
        if(r.success) {
          message.success("Added Lead Status Successfully.")
          closeModal()
          getData()
        } else {
          BadNotif(r)
        }
      })
  } 

  useEffect(()=>{
    getData()
  }, [])

  return(
    <>
      <Button
        type="primary"
        onClick={openModal}
      >
        Add Lead Status Item
      </Button>
      <ShowLeadStatus 
        data={data}
        load={load}
      />
      <AddLeadStatusModal 
        visible={vis}
        addLeadStatus={addData}
        close={closeModal}
      />
    </>
  )
}

export default LeadStatus