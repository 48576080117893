export default {
  AREAS: [
    "Montreal", 
    "Boston",
    "Out of Zone",
    "Toronto"
  ],
  LEAD_STATUS: [
    "Working",
    "Unqualified",
    "Qualified",
    "Engaged",
    "Interested", 
    "Not Interested", 
    "Not in Service Area", 
    "with SDR", 
    "Sent to Hubspot"
  ],
  STATUS: [
    "OPEN",
    "CLOSED"
  ],
  bools: [
    {
        value: "true",
        option: "Yes",
    },
    {
        value: "false",
        option: "No",
    },
]
}