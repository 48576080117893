import React from 'react'
import {Modal, Form, Input, Button} from 'antd'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const formTailLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12, offset: 6 },
};

const AddListModal = ({
  visible,
  addLeadStatus,
  close
}) => {
  return(
    <Modal
      visible={visible}
      onCancel={close}
      footer={null}
    >
      <Form
        onFinish={values=>addLeadStatus(values.value)}
        hideRequiredMark
      >
        <Form.Item
          rules={[{required: true}]}
          {...formItemLayout}
          name="value"
          label="Value"
        >
          <Input />
        </Form.Item>
        <Form.Item
          {...formTailLayout}
        >
          <Button
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AddListModal