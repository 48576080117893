import {
  Modal, Spin,
  Select,
  Tag
} from 'antd'
import React, { useEffect, useState } from 'react'
import AddInfluencerListsToUser from '../../Common/ApiCall/AddInfluencerListsToUser'
import GetInfluencerListsOfUser from '../../Common/ApiCall/GetInfluencerListsOfUser'
import RemoveInfluencerListsToUser from '../../Common/ApiCall/RemoveInfluencerListsToUser'
import { BadNotif, SuccessNotif } from '../../Common/Utils/SendNotification'

const {Option} = Select

type InfluencerListModalProps = {
  detailed_user_task: any,
  influencer_lists: any,
  visible: boolean,
  close: Function
}

const AddInfluencerListModal = ({
  visible,
  detailed_user_task,
  influencer_lists,
  close
}: InfluencerListModalProps) => {
  const [working, setWorking] = useState(false)
  const [list_ids, setListIds] = useState([])
  const [opts, setOpts] = useState([])
  const [selTag, setSelTag] = useState(null)

  const loading = () => setWorking(true)
  const loaded = () => setWorking(false)

  function addToList(item: any) {
    console.log(item)
    loading()
    AddInfluencerListsToUser(detailed_user_task.id, item.id)
      .then(r => {
        if (r.success) {
          SuccessNotif("Successfully Added")
          setListIds([...list_ids, item.id])
        } else {
          BadNotif(r)
        }
        loaded()
      })
  }

  function removeList(e: any, i) {
    e.preventDefault();
    let items = new Set(list_ids);
    items.delete(i);
    loading()
    RemoveInfluencerListsToUser(detailed_user_task.id, Array.from(items))
      .then(r => {
        if (r.success) {
          setListIds(Array.from(items))
          SuccessNotif("Removed")
        } else {
          BadNotif(r)
        }
        loaded()
      })
  }

  function getListValue(id: number) {
    for (let i of influencer_lists) {
      if (i.id === id) {
        return i.value
      }
    }
  }

  function getIdFromValue(v: string) {
    for (let i of influencer_lists) {
      if (i.value === v) {
        return i.id
      }
    }
  }

  function handleChange(v: any) {
    console.log(v)
    for (let i of influencer_lists) {
      if (i.value === v) {
        addToList(i)
        break
      }
    }
  }

  function notAlreadyInList(item: any) {
    let present = list_ids.includes(item.id)
    return !present
  }

  useEffect(() => {
    setOpts(influencer_lists.filter(notAlreadyInList))
    console.log(opts)
  }, [working])

  useEffect(() => {
    console.log('rendering...')
    if (detailed_user_task === null) {
      return;
    }
    loading()
    GetInfluencerListsOfUser(detailed_user_task.id)
      .then(r => {
        loaded()
        if (r.success) {
          let its = []
          r.data.influencer_list_ids.forEach(listItem => {
            its.push(listItem.id)
          })
          setListIds(its)
          setListIds(r.data.influencer_list_ids)
        } else {
          BadNotif(r)
        }
      })
  }, [detailed_user_task])

  useEffect(() => {
    console.log(opts)
  })

  if (detailed_user_task === null) {
    return null
  }

  return (
    <Modal
      visible={visible}
      key={detailed_user_task.id}
      onCancel={() => close()}
      footer={null}
    >
      <Select
        showSearch
        placeholder="Select List to Add Here"
        style={{ width: '100%' }}
        onSelect={handleChange}
      >
        {opts.map((influencer_list) => (
          <Option
            key={influencer_list.id}
            value={influencer_list.value}
            title={influencer_list.value}
          >
            {influencer_lists.value}
          </Option>
        ))}
      </Select>
      <>
        {
          list_ids.map((listId) => (
            <Tag
              closable
              key={listId}
              onClick={(v) => setSelTag(v)}
              onClose={(e) => removeList(e, listId)}
            >
              {getListValue(listId)}
            </Tag>
          ))
        }
      </>
      {
        working && <div className="has-text-centered"><Spin /></div>
      }
    </Modal>
  )
}

export default AddInfluencerListModal