import React from "react";
import NotificationTypeEnum from "../Models/NotificationTypeEnum";
import {notification} from "antd";
import {SmileOutlined, FrownOutlined} from "@ant-design/icons";

function SendNotification(title, message, isLong=false) {
    let icon;
    if (title === NotificationTypeEnum.Failure) {
        icon = <FrownOutlined/>;
    } else {
        icon = <SmileOutlined/>;
    }
     let duration = 5;
    if (isLong) {
        duration = 20;
    }
    notification.open({
        message: "",
        description: message,
        icon: icon,
        duration: duration
    });
}

export function SuccessNotif(message = 'Done', isLong = false) {
    SendNotification(NotificationTypeEnum.Success, message, isLong)
}

export function BadNotif( r = null , message = 'Something Went Wrong') {
    if(r && r.errors) {
        message = r.errors[0].message
    }
    SendNotification(NotificationTypeEnum.Failure, message)
}

export default SendNotification;