import { Button, Form, Input, InputNumber, Space, Switch } from 'antd'
import React, { useEffect } from 'react'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const formTailLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12, offset: 6 },
};

const EditUser = ({
  user, closeModal, update
}) => {

/*   useEffect(()=>{
    if(user) {
      editingForm.setFields({
        ig_username: user.ig_username,
        full_name: user.full_name,
        email: user.email,
        contact: user.contact,
        follower_count: user.follower_count,
        following_count: user.following_count,
        city: user.city,
        bio: user.bio,
        is_business_account: user.is_business_account,
        website: user.website,
        engagement_rate: user.engagement_rate,
        business_category_name: user.business_category_name
      })
    }
  }) */

  return(
    <Form
      initialValues={user}
      onFinish={update}
      key={user?.ig_username}
    >
      <Form.Item
        {...formItemLayout}
        label="Username"
        name="ig_username"
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label="Full Name"
        name="full_name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label="Email"
        name="email"
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label="Phone"
        name="contact"
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label="Followers Count"
        name="follower_count"
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label="Following Count"
        name="following_count"
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label="City"
        name="city"
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label="Bio"
        name='bio'
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="is_business_account"
        label="Business Account"
        {...formItemLayout}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        name="business_category_name"
        label="Business Category Name"
        {...formItemLayout}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="engagement_rate"
        label="Engagement Rate"
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        name="website"
        label="Website"
        {...formItemLayout}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...formTailLayout}
      >
        <Space>
          <Button htmlType="submit" type="primary">
            Update
          </Button>
          <Button onClick={closeModal}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default EditUser