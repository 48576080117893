class UserDetails {
  constructor(data) {
    let user = data.graphql.user;
    this.id = user.id;
    this.username = user.username;
    this.picture = user.profile_pic_url;
    this.name = user.full_name;
    this.follower_count = user.follower_count;
    this.following_count = user.following_count;
    this.biography = user.biography;
  }
}

export default UserDetails;
