import { Button, Col, Collapse, Divider, Modal, Pagination, Row, Space, Table, Tag, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import GetCategories from '../../Common/ApiCall/GetCategories'
import GetUsers from '../../Common/ApiCall/GetUsers'
import ContentWrapper from '../../Components/ContentWrapper'
import EditUser from './EditUser'
import UpdateUser from '../../Common/ApiCall/UpdateUser'
import ViewUserFilter from './ViewUserFilter'
import { BadNotif, SuccessNotif } from '../../Common/Utils/SendNotification'
import DownloadData from '../../Common/ApiCall/DownloadData'
import { DownloadOutlined } from '@ant-design/icons'

const {Title} = Typography
const {Panel} = Collapse

const initialFields = {
  is_email_there: null,
  is_contact_there: null,
  is_website_there: null,
  is_business_account: null,
  phone_number_starts_with: null,
  search_location: null,
  category_id: null,
  followers_greater_than: null,
  followers_less_than: null,
  posts_greater_than: null,
  posts_less_than: null
}

const PAGE_SIZE_OPTIONS = ["25", "50", "100"]
const DEFAULT_PAGE_SIZE = "50"

const ViewUsers = ({setKey}) => {
  const [load, setLoad] = useState(true)
  const [init, setInit] = useState(true)
  const [users, setUsers] = useState([])
  const [categories, setcategories] = useState([])
  const [editing, setediting] = useState(false)
  const [edUser, setEdUser] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [totalItems, setTotalItems] = useState(0)
  const [filters, setFilters] = useState(initialFields)
  const [initial, setInitial] = useState(true)
  const [dndl, setDnld] = useState(false)

  const columns = [
    {
      id: 'name',
      dataIndex: 'full_name',
      title: "Full Name"
    },
    {
      id: 'username',
      dataIndex: 'ig_username',
      title: 'Username'
    },
    {
      id: 'phone',
      dataIndex: 'contact',
      title: 'Phone Number'
    },
    {
      id: 'email',
      dataIndex: 'email',
      title: 'Email'
    },
/*     {
      id: 'category',
      dataIndex: 'category',
      title: 'Category',
      render: (categories) => (
        <>
          {categories && categories.map(category => {
            return (
              <Tag key={category.id}>
                {category.name}
              </Tag>
            )
          })}
        </>
      )
    }, */
    {
      title: 'Action',
      key: 'action',
      render: (item) => (
        <Space size="middle">
          <Button type="primary" onClick={()=>{setEdUser(item);setediting(true)}} >
            View / Edit
          </Button>
          <Button 
            target="_blank"
            rel="noopener noreferer"
            href={"https://www.instagram.com/"+item.ig_username} 
          >
            Open Profile 
          </Button>
        </Space>
      )
    }
  ]

  function closeModal() {
    setEdUser(null)
    setediting(false)
  }

  function saveUser(values) {
    setLoad(true)
    UpdateUser(values, filters.category_id, edUser.ig_id)
      .then(r => {
        setLoad(false)
        if(r.success) {
          SuccessNotif("User Updated")
        } else {
          BadNotif(r)
        }
        getUsers(filters)
      })
  }

  function filterBool(val) {
    return Object.fromEntries(Object.entries(val).filter(([_, v]) => v != null))
  }

  function getCategories() {
    setInit(true)
    GetCategories()
    .then(r => {
      setInit(false)
      setLoad(false)
      if(r.success) {
        setcategories(r.data)
        if(r.data.length) {
          setFilters({...filters, category_id: r.data[0].id})
        }
      }
    })
  }

  function getUsers(optValues = {}, pg_size = pageSize, cp = currentPage) {
    setLoad(true)
    optValues.page_size = pg_size
    optValues.page_number = cp
    let values = filterBool(optValues)
    if(!values.category_id) {setLoad(false);return;}
    GetUsers(values)
      .then(r => {
        setLoad(false)
        if(r.success) {
          setUsers(r.data.users)
          setTotalItems(r.data.count)
        }
      })
  }

  function filterUsers(values) {
    setFilters(values)
    getUsers(values)
  }

  function changePage(page, size) {
    setCurrentPage(page);
    setPageSize(size);
    getUsers(filters, size, page)
  }

  function download() {
    setDnld(true)
    DownloadData(filters)
      .then(r => {
        setDnld(false)
        if(r.success) {
          SuccessNotif('Downloading Resource')
          window.open(r.data)
        } else {
          BadNotif(r)
        }
      })
  }

  useEffect(()=>{
    setKey()
    getCategories()
  }, [])

  return(
    <>
      <Modal
        visible={editing}
        title="View/Edit User"
        okText="Update"
        footer={null}
        onCancel={closeModal}
        width={700}
      >
        <EditUser 
          user={edUser}
          closeModal={closeModal}
          update={saveUser}
        />
      </Modal>
      <Row justify="center" gutter={24}>
        <Col md={{span: 24}} lg={{span: 24}}>
          <ContentWrapper marginLess>
            <Title level={3} style={{fontWeight: 'medium'}}>
              Users
            </Title>
            <Space
              direction="vertical"
              style={{width: '100%'}}
            >
                  <ViewUserFilter 
                    categories={categories}
                    init={init}
                    filterUsers={filterUsers}
                    filters={filters}
                    initialFields={initialFields}
                    initial={initial}             
                    setInital={()=>setInitial(false)}   
                  />
                <div>
                  <Space>
                    <Title level={4}>
                      Total {totalItems} Items
                    </Title>
                    <Button loading={dndl} onClick={download} type="primary" shape="round" icon={<DownloadOutlined />}>
                      Download Data
                    </Button>
                  </Space>
                  <div className="menu-float-right">
                  <Pagination 
                        style={{
                          display: 'inline-block'
                        }}
                        current={currentPage}
                        pageSize={pageSize}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        total={totalItems}
                        onChange={changePage}
                      />
                  </div>
                </div>
              <Table 
                pagination={false && {
                  position: 'topRight',
                  onChange: (page, size) => {setCurrentPage(page);setPageSize(size);},
                  current: currentPage,
                  pageSize: pageSize,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  showSizeChanger: true,
                  total: totalItems
                }}
                bordered
                size="middle"
                loading={load}
                columns={columns}
                dataSource={users}
              />
            </Space>
          </ContentWrapper>
        </Col>
      </Row>
    </>
  )
}

export default ViewUsers