import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from './auth-header'

const AddLeadStatus = async (value) => {
  let apiResponse;
  if ( RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/crm/lead_status"), {
      value
    }, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
    data: null,
    success: true,
    errors: []
  }
}

export default AddLeadStatus
