import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from './auth-header'

const GetCRMUsers = async (values) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/crm/users"), 
    values
    , authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {

  return {
    data: {
      count: 4,
      users: [
        {
          id: 22,
          user_id: 2,
          campaign_id: 7,
          instagram_account_owner: "awdmkawmd",
          first_name: "",
          last_name: "",
          area_id: 1,
          lead_status_id: 1,
          lead_status_change_date: null,
          next_step_date: null,
          step_date_comments: [],
          hubspot_owner: "",
          contractor_type: "awmdkam",
          is_tracking: false,
          post_count: -1,
          igtv_count: -1,
          website: null,
          phone_number: "987678766",
          email: "",
          ig_id: "1",
          bio: "amawdkmwakmaw",
          username: "test",
          category: null,
          created_at: "2021-04-13T13:24:30.287Z",
          updated_at: "2021-04-13T13:24:30.287Z",
        },
        {
          id: 23,
          user_id: 2,
          campaign_id: 7,
          instagram_account_owner: "awdmkawmd",
          first_name: "",
          last_name: "",
          area_id: 2,
          lead_status_id: 4,
          lead_status_change_date: null,
          next_step_date: null,
          step_date_comments: [],
          hubspot_owner: "",
          contractor_type: "awmdkam",
          is_tracking: false,
          post_count: -1,
          igtv_count: -1,
          website: null,
          phone_number: "987678766",
          email: "",
          ig_id: "2",
          bio: "amawdkmwakmaw",
          username: "test",
          category: null,
          created_at: "2021-04-13T13:24:30.287Z",
          updated_at: "2021-04-13T13:24:30.287Z",
        },
        {
          id: 24,
          user_id: 2,
          campaign_id: 7,
          instagram_account_owner: "awdmkawmd",
          first_name: "",
          last_name: "",
          area_id: 2,
          lead_status_id: 1,
          lead_status_change_date: null,
          next_step_date: null,
          step_date_comments: [],
          hubspot_owner: "",
          contractor_type: "awmdkam",
          is_tracking: false,
          post_count: -1,
          igtv_count: -1,
          website: null,
          phone_number: "987678766",
          email: "",
          ig_id: "3",
          bio: "amawdkmwakmaw",
          username: "test",
          category: null,
          created_at: "2021-04-13T13:24:30.287Z",
          updated_at: "2021-04-13T13:24:30.287Z",
        },
        {
          id: 25,
          user_id: 2,
          campaign_id: 7,
          instagram_account_owner: "awdmkawmd",
          first_name: "",
          last_name: "",
          area_id: 1,
          lead_status_id: 5,
          lead_status_change_date: null,
          next_step_date: null,
          step_date_comments: [],
          hubspot_owner: "",
          contractor_type: "awmdkam",
          is_tracking: false,
          post_count: -1,
          igtv_count: -1,
          website: null,
          phone_number: "987678766",
          email: "",
          ig_id: "4",
          bio: "amawdkmwakmaw",
          username: "test",
          category: null,
          created_at: "2021-04-13T13:24:30.287Z",
          updated_at: "2021-04-13T13:24:30.287Z",
        },
      ],
    },
    success: true,
    error: null,
  };
}

export default GetCRMUsers
