import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Input,
  Switch,
  Row,
  Select,
  Space,
  Form,
  AutoComplete,
  message,
  InputNumber,
} from "antd";
import Loader from "../../Components/Loader";
import QueryAccountOwner from "../../Common/ApiCall/QueryAccountOwner";
import QueryContractorType from "../../Common/ApiCall/QueryContractorType";
import LeadsEnums from "./LeadsEnums";
import moment from "moment";

const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const rowProps = {
  gutter: 24,
};

const colProps = {
  md: { span: 11, offset: 1 },
  lg: { span: 7, offset: 1 },
};

const dateFormat = "DD/MM/YYYY";

const LeadsTableFilter = ({
  init,
  filterLeads,
  filters,
  initialFields,
  initial,
  setInital,
  areas,
  campaigns,
  lead_status,
}) => {
  const [leadForm] = Form.useForm();
  const [accountOwnerOptions, setAccountOwnerOptions] = useState([]);
  const [contractorOptions, setContractorOptions] = useState([]);
  const [usernameOptions, setUsernameOptions] = useState([])

  useEffect(()=>{
    console.log(filters)
    leadForm.setFieldsValue(filters)
    submit(filters)
  }, [filters])

  function submit(val = leadForm.getFieldsValue()) {
    filterLeads(val);
  }

  function renderDate(epoch) {
    let d = new Date(0);
    d.setUTCSeconds(epoch);
    d = d.toISOString().split("T")[0];
    console.log(d);
    return d;
  }

  function clear() {
    filterLeads(initialFields);
    leadForm.setFieldsValue(initialFields);
  }

  const onAccountOwnerSearch = (q) => {
    if(q.length===0) return;
    QueryAccountOwner(q).then((r) => {
      if (r.success) {
        setAccountOwnerOptions(r.data);
      }
    });
  };

  const onContractorSearch = (q) => {
    if(q.length===0) return;
    QueryContractorType(q).then((r) => {
      if (r.success) {
        setContractorOptions(r.data);
      }
    });
  };

  const onUsernameSearch = (q) => {
    if(q.length===0) return;
    
  }

  const onUsernameSelect = (i) => {
    leadForm.setFieldsValue({
      ig_username_starts_with: i
    })
    submit({...leadForm.getFieldValue(), ig_username_starts_with: i})
  }

  const onAccountSelect = (i) => {
    leadForm.setFieldsValue({
      instagram_account_owner: i
    })
    submit({...leadForm.getFieldsValue(), instagram_account_owner: i})
  }

  const onContractorSelect = (i) => {
    leadForm.setFieldsValue({
      contractor_type: i
    })
    submit({...leadForm.getFieldsValue(), contractor_type: i})
  }
 
  return (
    <Card>
      {
        init ? (
          <Loader />
        ) : (
          <Form
            form={leadForm}
            layout="horizantal"
            initialValues={filters}
            onFinish={filterLeads}
            hideRequiredMark
          >
            <Collapse
              style={{ backgroundColor: "black !important" }}
              defaultActiveKey={["1"]}
            >
              <Panel style={{ color: "black !important" }} header="Filters" key="1">
                <Row>
                  <ColField>
                    <Form.Item 
                      name="area_id"
                    >
                      <Select
                        placeholder="Select a Area"
                        style={{ maxWidth: "400px" }}
                        onChange={(e) => submit({ ...filters, area_id: e })}
                      >
                        {areas.map((area) => (
                          <Option value={area.id} key={area.id} title={area.value}>
                            {area.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </ColField>
                  <ColField>
                    <Form.Item
                      name="campaign_id"
                    >
                      <Select
                        style={{ maxWidth: "400px" }}
                        onChange={(e) => submit({ ...filters, campaign_id: e })}
                        placeholder="Select a Campaign Name"
                      >
                        {campaigns.map((item, index) => (
                          <Option value={item.id} key={item.id} title={item.value}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </ColField>
                  <ColField>
                    <Form.Item
                      name="lead_status_id"
                    >
                      <Select
                        placeholder="Select a Lead Status"
                        style={{ maxWidth: "400px" }}
                        onChange={(e) => submit({ ...filters, lead_status_id: e })}
                      >
                        {lead_status.map((item, index) => (
                          <Option value={item.id} key={item.id} title={item.value}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </ColField>
                </Row>
                <Row
                  {...rowProps}
                >
                  <ColField>
                    <Form.Item
                      noStyle
                    >
                      Ig Username Starts With
                      <Form.Item
                        value="ig_username_starts_with"
                      >
                        <Input />
                      </Form.Item>
                    </Form.Item>
                  </ColField>
                  <ColField>
                    <Form.Item
                      noStyle
                    >
                      No Activity Greater than days
                      <Form.Item
                        value="no_activity_greater_than_days"
                      >
                        <InputNumber />
                      </Form.Item>
                    </Form.Item>
                  </ColField>
                </Row>
{/*                 <Row 
                  {...rowProps}
                >
                  <ColField>
                    <Form.Item
                      noStyle
                    >
                      Is Next Step Date Empty?
                      <Form.Item
                        name="next_step_date_is_empty"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </Form.Item>
                  </ColField>
                  <ColField>
                    <Form.Item 
                      shouldUpdate 
                      noStyle
                    >
                      {()=>(
                        <Form.Item
                          label="Next Step Date"
                          name="next_step_date_range"
                        >
                          <RangePicker
                            format={dateFormat}
                            disabled={leadForm.getFieldValue(
                              "next_step_date_is_empty"
                            )}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </ColField>
                  <ColField>
                    <Form.Item 
                      name="tracking_dates" 
                      label="Tracking Dates" 
                      shouldUpdate
                    >
                      <RangePicker 
                        format={dateFormat}
                      />
                    </Form.Item>
                  </ColField>
                </Row> */}
                <Row gutter={24}>
                  <ColField>
                    <Form.Item
                      noStyle
                    >
                      Is Lead Status Date Empty?
                      <Form.Item
                        name="lead_status_date_change_is_empty"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                    </Form.Item>
                  </ColField>
                  <ColField>
                    <Form.Item 
                      noStyle
                      shouldUpdate
                    >
                      {()=>(
                        <Form.Item
                          label="Lead Status Date Change" 
                          name="lead_status_date_range"
                        >
                          <RangePicker
                            disabled={leadForm.getFieldValue(
                              "lead_status_date_change_is_empty"
                            )}
                            format={dateFormat}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  </ColField>
                  <ColField>
                    <Form.Item
                      noStyle
                    >
                      Ig Username Starts with
                      <Form.Item>
                        <AutoComplete
                          placeholder="Enter Ig Username"
                          allowClear
                        >

                        </AutoComplete>
                      </Form.Item>
                    </Form.Item>
                  </ColField>
                </Row>
                <Row gutter={24}>
                  <ColField>
                    <Form.Item
                      noStyle
                    >
                      Account Owner
                      <Form.Item>
                        <AutoComplete
                          placeholder="Enter Account Owner to Continue"
                          onSearch={onAccountOwnerSearch}
                          onSelect={onAccountSelect}
                          allowClear
                          onChange={(e) => {if(e) {} else {onAccountSelect('')} }}
                        >
                          {accountOwnerOptions.map((item, index) => (
                            <Option key={index} value={item} title={item}>
                              {item}
                            </Option>
                          ))}
                        </AutoComplete>
                      </Form.Item>
                    </Form.Item>
                  </ColField>
                  <ColField>
                    <Form.Item
                      noStyle
                    >
                      Contractor Type
                      <Form.Item>
                        <AutoComplete
                          placeholder="Enter Contractor Type to Continue"
                          onSearch={onContractorSearch}
                          onSelect={onContractorSelect}
                          allowClear
                          onChange={(e) => {console.log(e);if(e) {} else {onContractorSelect('')} }}
                        >
                          {contractorOptions.map((item, index) => (
                            <Option key={index} value={item} title={item}>
                              {item}
                            </Option>
                          ))}
                        </AutoComplete>
                      </Form.Item>
                    </Form.Item>
                  </ColField>
                  <ColField>
                    <Form.Item shouldUpdate>
                      {() => (
                        <Space>
                          <Button
                            type="primary"
                            onClick={() => submit()}
                            disabled={!leadForm.isFieldsTouched()}
                          >
                            Update Filters
                          </Button>
                          <Button type="dashed" onClick={clear}>
                            Clear Filters
                          </Button>
                        </Space>
                      )}
                    </Form.Item>
                  </ColField>
                </Row>
              </Panel>
            </Collapse>
          </Form>          
        )
      }
    </Card>
  );
};

const ColField = ({ children }) => {
  return <Col {...colProps}>{children}</Col>;
};

export default LeadsTableFilter;
