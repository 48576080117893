import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from './auth-header'

const DownloadUsersInfluencers = async (
  values: any
) => {
  let apiResponse;
  if ( RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/influencers/download"), 
      values
    , 
    authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
    data: "resource_url_here",
    success: true,
    errors: []
  }
}

export default DownloadUsersInfluencers
