import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from './auth-header'

const GetOwners = async () => {
  let apiResponse;
  if ( RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/crm/owner"), {}, 
    authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
    data: [
      {id: 1, value: "Montreal"},
      {id: 1, value: "Boston"},
      {id: 3, value:"Out of Zone"},
      {id: 4,value:"Toronto"}
    ],
    success: true,
    errors: []
  }
}

export default GetOwners
