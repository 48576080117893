import {
  Button,
  Col,
  Pagination,
  Row,
  Space,
  Table,
  Typography,
  message,
  Popconfirm
} from "antd";
import React, { useEffect, useState } from "react";
import ContentWrapper from "../../Components/ContentWrapper";
import LeadsTableFilter from "./LeadsTableFilter";
import { BadNotif, SuccessNotif } from "../../Common/Utils/SendNotification";
import GetCRMUsers from "../../Common/ApiCall/GetCRMUsers";
import GetAreas from "../../Common/ApiCall/GetAreas";
import {Link, useLocation} from 'react-router-dom'
import GetLeadStatuses from "../../Common/ApiCall/GetLeadStatuses";
import GetCampaigns from "../../Common/ApiCall/GetCampaigns";
import { filterBool } from "../../Components/helperFunction";
import UpdateUserTracking from "../../Common/ApiCall/UpdateUserTracking";
import moment from 'moment'

const { Title } = Typography;

const initialFields = {
  area_id: null,
  account_owner: null,
  lead_status_id: null,
  next_step_date_is_empty: null,
  next_step_date_range: [],
  lead_status_date_change_is_empty: null,
  lead_status_date_range: [],
  contractor_type: null,
  tracking_dates: [],
  campaign_id: null,
  ig_username_starts_with: null,
  no_activity_greater_than_days: null
};

const PAGE_SIZE_OPTIONS = ["25", "50", "100"];
const DEFAULT_PAGE_SIZE = 50;

function momentObjectToEpoch(object) {
  return (object.valueOf())/1000
}

function epochToMoment(epoch) {
  return moment(epoch*1000)
}

/*
ig_username
Hubspot owner
lead_status
Instagram account owner
Is Tracking
Category
Campaign Name
*/

const LeadTables = ({ setKey, ...rest }) => {
  const [load, setLoad] = useState(true);
  const [init, setInit] = useState(true);
  const [users, setUsers] = useState([]);
  const [campaigns, setcampaigns] = useState([]);
  const [areas, setAreas] = useState([]);
  const [lead_status, set_lead_status] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalItems, setTotalItems] = useState(0);
  const [filters, setFilters] = useState(initialFields);
  const [initial, setInitial] = useState(true);
  const [dndl, setDnld] = useState(false);

  const getValueFromId = (array, index) => {
    for(let i=0;i<array.length;i++) {
      if(array[i]['id'] === index) {
        return array[i]['value']
      }
    }
    return "NA"
  }

  function startTracking(user_id) {
    setLoad(true);
    UpdateUserTracking(user_id, true).then((r) => {
      setLoad(false);
      if (r.success) {
        message.success("Started Tracking for User");
        getLeads(filters)
      } else {
        BadNotif(r);
      }
    });
  }

  function stopTracking(user_id) {
    setLoad(true);
    UpdateUserTracking(user_id, false).then((r) => {
      setLoad(false);
      if (r.success) {
        message.success("Stopped Tracking for User");
        getLeads(filters)
      } else {
        BadNotif(r);
      }
    });
  }

  const columns = [
    {
      id: "username",
      dataIndex: "username",
      title: "Ig Username",
    },
    {
      id: 'last_step_done_date',
      dataIndex: 'last_step_done_date',
      title: 'Last Step Done Date'
    },
    {
      id: "hubspot_owner",
      dataIndex: "hubspot_owner",
      title: "Hubspot Owner",
    },
    {
      id: "lead_status_id",
      dataIndex: "lead_status_id",
      title: "Lead Status",
      render: i => getValueFromId(lead_status, i)
    },
    {
      id: "instagram_account_owner",
      dataIndex: "instagram_account_owner",
      title: "Instagram Account Owner",
    },
    {
      id: "category",
      dataIndex: "category",
      title: "Category",
    },
    {
      id: "campaign_id",
      dataIndex: "campaign_id",
      title: "Campaign Name",
      render: i => getValueFromId(campaigns, i)
    },
    {
      title: "Action",
      key: "action",
      render: (item) => (
        <Space size="middle">
          <Button>
            <Link
              to={{
                pathname: "/leads/" + item.id,
                state: {
                  filters: filterBool(filters)
                }
              }}
            >
              View / Edit
            </Link>
          </Button>
          {item.is_tracking ? (
            <Popconfirm
              title="Are you sure you want to stop Tracking for this User?"
              onConfirm={() => stopTracking(item.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button 
                type="danger" 
              >
                Stop Tracking
              </Button>
            </Popconfirm>
          ) : (
            <Button type="primary" onClick={() => startTracking(item.id)}>
              Start Tracking
            </Button>
          )}
        </Space>
      ),
    },
  ];

  function getAreas() {
    setInit(true);
    GetAreas().then((r) => {
      setInit(false);
      setLoad(false);
      if (r.success) {
        setAreas(r.data);
      }
    });
  }

  function getCampaigns() {
    GetCampaigns().then((r) => {
      if (r.success) {
        setcampaigns(r.data);
      }
    });
  }

  function getLeadStatus() {
    GetLeadStatuses().then((r) => {
      if (r.success) {
        set_lead_status(r.data);
      }
    });
  }

  function filterBool(val) {
    let v = Object.fromEntries(
      Object.entries(val).filter(([_, v]) => v != null)
    );
    if(v.next_step_date_range!==undefined) {
      let a = null
      if(v.next_step_date_range[0]) {
        a = {
          start: momentObjectToEpoch(v.next_step_date_range[0].startOf("day")),
          end: momentObjectToEpoch(v.next_step_date_range[1].endOf("day"))
        }
        v.next_step_date_range = a
      }
      delete v.next_step_date_is_empty
      delete v.next_step_date_range
      if(a !== null) {v.next_step_date_range = a}
    }
    if(v.lead_status_date_range!==undefined) {
      let a = null
      if(v.lead_status_date_range[[0]]) {
        a = {
          start: momentObjectToEpoch(v.lead_status_date_range[0].startOf("day")),
          end: momentObjectToEpoch(v.lead_status_date_range[1].endOf("day"))
        }
      }
      delete v.lead_status_date_change_is_empty
      delete v.lead_status_date_range
      if(a !== null) {v.lead_status_date_range = a}
    }
    if(v.tracking_dates!==undefined) {
      let a = null
      if(v.tracking_dates[0]) {
        a = {
          start: momentObjectToEpoch(v.tracking_dates[0]),
          end: momentObjectToEpoch(v.tracking_dates[1])
        }
      }
      delete v.tracking_dates
      if(a!==null) {v.tracking_dates = a}
    }
    return v
  }

  function simpler(val) {
    let v = Object.fromEntries(
      Object.entries(val).filter(([_, v]) => v != null)
    );
    if(v.next_step_date_range!==undefined) {
      let a = null
      if(v.next_step_date_range.start) {
        a = [
          epochToMoment(v.next_step_date_range.start),
          epochToMoment(v.next_step_date_range.end)
        ]
        v.next_step_date_range = a
      }
      delete v.next_step_date_is_empty
      delete v.next_step_date_range
      if(a !== null) {v.next_step_date_range = a}
    }
    if(v.lead_status_date_range!==undefined) {
      let a = null
      if(v.lead_status_date_range.start) {
        a = [
          epochToMoment(v.lead_status_date_range.start),
          epochToMoment(v.lead_status_date_range.end)
        ]
      }
      delete v.lead_status_date_change_is_empty
      delete v.lead_status_date_range
      if(a !== null) {v.lead_status_date_range = a}
    }
    if(v.tracking_dates!==undefined) {
      let a = null
      if(v.tracking_dates.start) {
        a = [
          epochToMoment(v.tracking_dates.start),
          epochToMoment(v.tracking_dates.end)
        ]
      }
      delete v.tracking_dates
      if(a!==null) {v.tracking_dates = a}
    }
    console.log(v)
    return v
  }

  function getLeads(optValues = {}, pg_size = pageSize, cp = currentPage) {
    setLoad(true);
    optValues.page_size = pg_size;
    optValues.page_number = cp;
    let values = filterBool(optValues);
    GetCRMUsers(values).then((r) => {
      setLoad(false);
      if (r.success) {
        setUsers(r.data.users);
        setTotalItems(r.data.count);
      } else {
        BadNotif(r)
      }
    });
  }

  function filterLeads(values) {
    setFilters(values);
    getLeads(values);
  }

  function changePage(page, size) {
    setCurrentPage(page);
    setPageSize(size);
    getLeads(filters, size, page);
  }

  /*   function download() {
    setDnld(true)
    DownloadData(filters)
      .then(r => {
        setDnld(false)
        if(r.success) {
          SuccessNotif('Downloading Resource')
          window.open(r.data)
        } else {
          BadNotif(r)
        }
      })
  } */

  function initalCalls() {
    getAreas();
    getLeadStatus();
    getCampaigns();
  }

  let h = useLocation()

  useEffect(() => {
    setKey();
    initalCalls();
    if(h.state && h.state.filters) {
      let f = simpler(h.state.filters)
      setFilters(f)
      getLeads(f)
    } else {
      getLeads();
    }
  }, []);

  return (
    <Row justify="center" gutter={24}>
      <Col md={{ span: 24 }} lg={{ span: 24 }}>
        <ContentWrapper marginLess>
          <Title level={3} style={{ fontWeight: "medium" }}>
            CRM Users
          </Title>
          <Space direction="vertical" style={{ width: "100%" }}>
            <LeadsTableFilter
              init={init}
              filterLeads={filterLeads}
              filters={filters}
              initialFields={initialFields}
              initial={initial}
              setInital={() => setInitial(false)}
              areas={areas}
              lead_status={lead_status}
              campaigns={campaigns}
            />
            <div>
              <Space>
                <Title level={4}>Total {totalItems} Items</Title>
                {/*                 <Button loading={dndl} onClick={download} type="primary" shape="round" icon={<DownloadOutlined />}>
                  Download Data
                </Button> */}
              </Space>
              <div className="menu-float-right">
                <Pagination
                  style={{
                    display: "inline-block",
                  }}
                  current={currentPage}
                  pageSize={pageSize}
                  pageSizeOptions={PAGE_SIZE_OPTIONS}
                  total={totalItems}
                  onChange={changePage}
                />
              </div>
            </div>
            <Table
              pagination={
                false && {
                  position: "topRight",
                  onChange: (page, size) => {
                    setCurrentPage(page);
                    setPageSize(size);
                  },
                  current: currentPage,
                  pageSize: pageSize,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  showSizeChanger: true,
                  total: totalItems,
                }
              }
              bordered
              size="middle"
              loading={load}
              columns={columns}
              dataSource={users}
            />
          </Space>
        </ContentWrapper>
      </Col>
    </Row>
  );
};

export default LeadTables;
