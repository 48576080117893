import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from './auth-header'

const QueryContractorType = async (query) => {
  let apiResponse;
  if ( RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/crm/contractor_type"), {
      query
    }, 
    authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
    data:  ["contractor_1", "contractor_2", "contractor_3"],
    success: true,
    errors: []
  }
}

export default QueryContractorType
