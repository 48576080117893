import {
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Button,
  Skeleton
} from 'antd'
import React, { useEffect, useState } from 'react'
import AddTaskCall from '../../Common/ApiCall/AddTask'
import GetOwners from '../../Common/ApiCall/GetOwners';
import { BadNotif } from '../../Common/Utils/SendNotification';

const {Option} = Select

const dateFormat = "DD/MM/YYYY";

function momentObjectToEpoch(object) {
  return (object.valueOf())/1000
}

type props = {
  show: boolean,
  crmOwners?: any,
  back: any,
  refresh: any,
  userId?: Number
}

const STATUS = [
  "OPEN",
  "CLOSED"
]

const AddTask = ({
  userId
}: props) => {
  const [load, setLoad] = useState(false)
  const [newForm] = Form.useForm()
  const [init, setInit] = useState(true)
  const [crmOwners, setCrmOwners] = useState([])

  function getCrmOwners() {
    setInit(true)
    GetOwners()
      .then(r => {
        setInit(false)
        if(r.success) {
          setCrmOwners(r.data)
        }
      })
  }

  function addTask(values: any) {
    setLoad(true)
    AddTaskCall(
      momentObjectToEpoch(values.task_date), 
      values.crm_owner_id,
      values.description,
      values.title,
      values.status,
      userId
    )
      .then(r => {
        setLoad(false)
        if(r.success) {
          message.success("Task Added Successfully.")
          clear()
        } else {
          BadNotif(r)
        }
      })
  }

  function clear() {
    newForm.resetFields()
  }

  useEffect(()=>{
    getCrmOwners()
  }, [])

  if(init) {
    return <Skeleton />
  }

  return (
    <Form
    onFinish={addTask}
    hideRequiredMark
    form={newForm}
  >
    <Form.Item
      noStyle
      required
    >
      Title
      <Form.Item
        name="title"
      >
        <Input />
      </Form.Item>
    </Form.Item>
    <Form.Item
      noStyle
    >
      Description
      <Form.Item
        name="description"
      >
        <Input.TextArea />
      </Form.Item>
    </Form.Item>
    <Form.Item
      noStyle
    >
      <Form.Item 
        name="status"
        required
      >
        <Select
          placeholder="Select a Status"
          style={{ maxWidth: "400px" }}
        >
          {STATUS.map((item) => (
            <Option 
              value={item} 
              key={item} 
              title={item}
            >
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form.Item>
    <Form.Item
      noStyle
    >
      Task Date
      <Form.Item
        name="task_date"
        rules={[{required: true}]}
      >
        <DatePicker 
          format={dateFormat}
        />
      </Form.Item>
    </Form.Item>
    <Form.Item
      noStyle
    >
      <Form.Item
        label="Owner"
        name="crm_owner_id"
        rules={[{ required: true }]}
      >
        <Select 
          style={{ maxWidth: "400px" }}
        >
          {crmOwners.map((item) => (
            <Select.Option
              value={item.id}
              key={item.id}
              title={item.owner_name}
            >
              {item.owner_name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form.Item>
    <Form.Item
      shouldUpdate
    >
      {()=>(
        <Button
          type='primary'
          htmlType='submit'
          loading={load}
        >
          Submit
        </Button>
      )}
    </Form.Item>
  </Form>
  )
}

export default AddTask