import { Divider, Form, Typography } from 'antd'
import React, { useEffect } from 'react'
import ContentWrapper from '../Components/ContentWrapper'

const {Title} = Typography

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const MyAccount = ({user, setKey}) => {

  useEffect(()=>{
    setKey()
  }, [])

  return(
    <ContentWrapper>
      <Title level={4}>
        My Account
      </Title>
      <Divider />
      <Form
        {...layout}
      >
        <Form.Item 
          label="Email"
        >
          {user.email}
        </Form.Item>
        <Form.Item
          label="Daily Limit"
        >
          {user.daily_max}
        </Form.Item>
      </Form>
    </ContentWrapper>
  )
}

export default MyAccount