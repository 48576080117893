import React, {useEffect} from "react";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import FaqComponent from '../Components/Faq/FaqComponent'
import ContentWrapper from "../Components/ContentWrapper";

const FaqData = [
  {
    question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    answer: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using \'Content here, content here\','
  },
  {
    question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    answer: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using \'Content here, content here\','
  },
  {
    question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    answer: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using \'Content here, content here\','
  },
  {
    question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    answer: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using \'Content here, content here\','
  },
  {
    question: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    answer: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using \'Content here, content here\','
  }
]

const FAQ = ({setKey}) => {

  useEffect(()=>{
    setKey()
  }, [])

  return(
    <>
      <FaqComponent 
        siteAddress="one_market"
        columns={1}
        grouped={false}
      />
    </>
  )
}

export default FAQ