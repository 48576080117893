import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Input,
  Row,
  Select,
  Space,
  Form,
  InputNumber,
  AutoComplete
} from "antd";
import Loader from "../../Components/Loader";
import TaskEnums from "./TasksEnums";
import moment from "moment";
import GetIgUsernames from "../../Common/ApiCall/GetIgUsernames";

const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const rowProps = {
  gutter: 24,
};

const colProps = {
  md: { span: 11, offset: 1 },
  lg: { span: 7, offset: 1 },
};

const dateFormat = "DD/MM/YYYY";

const TasksTableFilter = ({
  init,
  filterLeads,
  filters,
  initialFields,
  initial,
  setInital,
  crm_owners,
  campaigns
}) => {
  const [taskForm] = Form.useForm();
  const [igUsernames, setIgUsernames] = useState([])

  useEffect(() => {
    taskForm.setFieldsValue(filters)
    if (!init) {
      console.log('here')
      console.log(filters)
      submit(filters) 
    }
  }, [filters, init])

  function submit(val = taskForm.getFieldsValue()) {
    console.log(val)
    filterLeads(val);
  }

  function renderDate(epoch) {
    let d = new Date(0);
    d.setUTCSeconds(epoch);
    return d.toISOString().split("T")[0];
  }

  const onIgUsernameSearch = (q) => {
    if(q.length===0) return;
    GetIgUsernames(q).then((r) => {
      if (r.success) {
        setIgUsernames(r.data);
      }
    });
  };

  const onIgUsernameSelect = (i) => {
    taskForm.setFieldsValue({
      ig_username: i
    })
    submit({...taskForm.getFieldsValue(), ig_username: i})
  }

  function clear() {
    filterLeads(initialFields);
    taskForm.setFieldsValue(initialFields);
  }
 
  return (
    <Card>
      {
        init ? (
          <Loader />
        ) : (
          <Form
            form={taskForm}
            layout='horizontal'
            initialValues={filters}
            onFinish={filterLeads}
            hideRequiredMark
          >
            <Collapse
              style={{ backgroundColor: "black !important" }}
              defaultActiveKey={["1"]}
            >
              <Panel style={{ color: "black !important" }} header="Filters" key="1">
                <Row {...rowProps} >
                  <ColField>
                    <Form.Item 
                      name="crm_owner_id"
                    >
                      <Select
                        placeholder="Select a Crm Owner"
                        style={{ maxWidth: "400px" }}
                        onChange={(e) => submit({ ...filters, crm_owner_id: e })}
                      >
                        {crm_owners.map((item) => (
                          <Option 
                            value={item.id} 
                            key={item.id} 
                            title={item.owner_name}
                          >
                            {item.owner_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </ColField>
                  <ColField>
                    <Form.Item 
                      name="status"
                    >
                      <Select
                        placeholder="Select a Status"
                        style={{ maxWidth: "400px" }}
                        onChange={(e) => submit({ ...filters, status: e })}
                      >
                        {TaskEnums.STATUS.map((item) => (
                          <Option 
                            value={item} 
                            key={item} 
                            title={item}
                          >
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </ColField>
                  <ColField>
                  <Form.Item 
                      name="campaign_id"
                    >
                      <Select
                        placeholder="Select a Campaign"
                        style={{ maxWidth: "400px" }}
                        onChange={(e) => submit({ ...filters, campaign_id: e })}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                        optionFilterProp="children"
                      >
                        {campaigns.map((item) => (
                          <Option 
                            value={item.id} 
                            key={item.id} 
                            title={item.value}
                          >
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </ColField>
                </Row>
                <Row
                  {...rowProps}
                >
                  <ColField>
                    <Form.Item
                      noStyle
                    >
                      No Activity Greater than days
                      <Form.Item
                        name="no_activity_greater_than_days"
                      >
                        <InputNumber />
                      </Form.Item>
                    </Form.Item>
                  </ColField>
                  <ColField>
                    <Form.Item
                      noStyle
                    >
                      Task Date
                      <Form.Item 
                        name="date" 
                      >
                        <RangePicker 
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Form.Item>
                  </ColField>
                  <ColField>
                  <Form.Item
                      noStyle
                    >
                      Task Name Starts With
                      <Form.Item
                        name="task_name_starts_with"
                      >
                        <Input />
                      </Form.Item>
                    </Form.Item>
                  </ColField>
                  <ColField>
                    <Form.Item
                      noStyle
                    >
                      Ig Username
                      <Form.Item>
                        <AutoComplete
                          placeholder="Enter Ig Username to Continue"
                          onSearch={onIgUsernameSearch}
                          onSelect={onIgUsernameSelect}
                          allowClear
                          onChange={(e) => {console.log(e);if(e) {} else {onIgUsernameSelect('')} }}
                        >
                          {igUsernames.map((item, index) => (
                            <Option key={index} value={item} title={item}>
                              {item}
                            </Option>
                          ))}
                        </AutoComplete>
                      </Form.Item>
                    </Form.Item>
                  </ColField>
                  <ColField>
                    <Form.Item shouldUpdate>
                      {() => (
                        <Space>
                          <Button
                            type="primary"
                            onClick={() => submit()}
                            disabled={!taskForm.isFieldsTouched()}
                          >
                            Update Filters
                          </Button>
                          <Button type="dashed" onClick={clear}>
                            Clear Filters
                          </Button>
                        </Space>
                      )}
                    </Form.Item>
                  </ColField>
                </Row>
              </Panel>
            </Collapse>
          </Form>          
        )
      }
    </Card>
  );
};

const ColField = ({ children }) => {
  return <Col {...colProps}>{children}</Col>;
};

export default TasksTableFilter;
