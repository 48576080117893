import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from './auth-header'

const GetProcesses = async () => {
  let apiResponse;
  if ( RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/tasks"), 
      {}
    , authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
      data: {
          count: 139,
          tasks: [
              {
                  id: 4,
                  crm_user_id: 166,
                  crm_owner_id: 1,
                  task_date: "2021-05-23T12:01:51.315Z",
                  status: "OPEN",
                  description: "",
                  title: "New Story",
                  created_at: "2021-05-23T12:01:51.328Z",
                  updated_at: "2021-05-23T12:01:51.328Z",
                  ig_username: "aapowertoolrepair",
              }
          ],
      },
      success: true,
      error: null,
  };
}

export default GetProcesses
