import { DownloadOutlined, UserOutlined } from '@ant-design/icons'
import {
  Button, Col,
  Divider, Pagination,
  Row, Space, Table,
  Typography,
  Avatar,
  message
} from 'antd'
import React, { useEffect, useState } from 'react'
import DownloadUsersInfluencers from '../../Common/ApiCall/DownloadUsersInfluencer'
import GetBusinessCategoryNames from '../../Common/ApiCall/GetBusinessCategoryNames'
import GetInfluencerLists from '../../Common/ApiCall/GetInfluencerLists'
import GetInfluencers from '../../Common/ApiCall/GetInfluencers'
import { BadNotif, SuccessNotif } from '../../Common/Utils/SendNotification'
import ContentWrapper from '../../Components/ContentWrapper'
import AddInfluencerListModal from './AddInfluencerListModal'
import InfluencersFilter from './InfluencersFilter'

const {Title} = Typography

export type InfluencersProps = {
  setKey: Function,
  myKey: string
}

const initialFields = {
  is_email_there: null,
  phone_number_starts_with: null,
  followers_greater_than: null,
  followers_less_than: null,
  following_greater_than: null,
  following_less_than: null,
  posts_greater_than: null,
  posts_less_than: null,
  business_category_name_starts_with: null,
  engagement_rate_greater_than: null,
  engagement_rate_less_than: null,
  only_zak: true
}

const PAGE_SIZE_OPTIONS = ["25", "50", "200"]
const DEFAULT_PAGE_SIZE = "100"

const Influencers = ({
  setKey,
  myKey
}: InfluencersProps) => {
  const [load, setLoad] = useState(true)
  const [init, setInit] = useState(true)
  const [users, setUsers] = useState([])
  const [categories, setcategories] = useState([])
  const [editing, setediting] = useState(false)
  const [edUser, setEdUser] = useState(null)
  const [changeListItem, setChangeId] = useState<any | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [totalItems, setTotalItems] = useState(0)
  const [filters, setFilters] = useState(initialFields)
  const [initial, setInitial] = useState(true)
  const [dndl, setDnld] = useState(false)
  const [lists, setLists] = useState([])

  const columns = [
    {
      id: 'picture',
      dataIndex: 'ig_id',
      width: 50,
      render: (ig_id: string) => (
        <Avatar
          src={`https://influencex-profile-pictures.nyc3.digitaloceanspaces.com/${ig_id}.jpg`}
          icon={<UserOutlined />}
        />
      )
    },
    {
      id: 'username',
      dataIndex: 'ig_username',
      title: 'Username'
    },
    {
      id: 'email',
      dataIndex: 'email',
      title: 'Email'
    },
    {
      id: 'phone',
      dataIndex: 'contact',
      title: 'Phone Number'
    },
    {
      id: 'category',
      dataIndex: 'category_name',
      title: 'Category'
    },
    {
      id: 'follower_count',
      dataIndex: 'follower_count',
      title: 'Follower Count'
    },
    {
      id: 'post_count',
      dataIndex: 'post_count',
      title: 'Post Count'
    },
    {
      id: 'average_post_day_gap',
      dataIndex: 'average_post_day_gap',
      title: 'Avg. Post Gap Days'
    },
    {
      id: 'average_likes',
      dataIndex: 'average_likes',
      title: 'Average Likes'
    },
    {
      id: 'average_comments',
      dataIndex: 'average_comments',
      title: 'Average Comments'
    },
    {
      id: 'engagement_rate',
      dataIndex: 'engagement_rate',
      title: 'Engg. Rate'
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right' as any,
      width: 280,
      render: (item) => (
        <Space size="middle">
{/*           <Button type="primary" onClick={()=>{setEdUser(item);setediting(true)}} >
            More Details
          </Button> */}
          <Button 
            target="_blank"
            rel="noopener noreferer"
            href={"https://www.instagram.com/"+item.ig_username} 
          >
            Open Profile 
          </Button>
          <Button
            type='primary'
            onClick={() => {
              changeLists(item)
            }}
          >
            Add To List
          </Button>
        </Space>
      )
    }
  ]

  function closeModal() {
    setEdUser(null)
    setediting(false)
  }

  useEffect(() => {
    if (myKey != "13") {
      setKey()
    }
  }, [])

  const changeLists = (i: any) => setChangeId(i)
  const closeChangeLists = () => setChangeId(null)

  useEffect(() => {
    if (myKey == '13') {
      getCategories()
      getLists()
      getUsers(filters)
    }
  }, [myKey])

  function getCategories() {
    setInit(true)
    GetBusinessCategoryNames()
    .then(r => {
      setInit(false)
      if (r.success) {
        let good_values = r.data.filter(item => item)
        setcategories(good_values)
/*         if(r.data.length) {
          setFilters({...filters, business_category_name_starts_with: r.data[0]})
        } */
      }
    })
  }

  function getLists() {
    setInit(true)
    GetInfluencerLists()
      .then(r => {
        setInit(false)
        if(r.success) {
          setLists(r.data.lists)
        }
      })
  }

  function filterBool(val) {
    return Object.fromEntries(Object.entries(val).filter(([_, v]) => v != null))
  }

  function getUniqueItemsFromArray(array: any[]) {
    let out = Object.values(
      array.reduce((c, e) => {
        if (!c[e.ig_id]) c[e.ig_id] = e;
        return c;
      }, {})
    )
    return out;
  }

  function getUsers(optValues: any, pg_size = pageSize, cp = currentPage) {
    setLoad(true)
    optValues.page_size = pg_size
    optValues.page_number = cp
    let values = filterBool(optValues)
    GetInfluencers(values)
      .then(r => {
        setLoad(false)
        if (r.success) {
          setUsers(getUniqueItemsFromArray(r.data.users))
          setTotalItems(r.data.count)
        }
      })
  }

  function filterUsers(values) {
    setFilters(values)
    getUsers(values)
  }

  function changePage(page, size) {
    setCurrentPage(page);
    setPageSize(size);
    getUsers(filters, size, page)
  }

  function download() {
    setDnld(true)
    DownloadUsersInfluencers(filters)
      .then(r => {
        setDnld(false)
        if(r.success) {
          SuccessNotif('Downloading Resource')
          window.open(r.data)
        } else {
          BadNotif(r)
        }
      })
  }

  return (
    <Row gutter={24}>
      <Col span={24}>
        <ContentWrapper>
          <Title level={3}>
            Influncers
          </Title>
          <Divider />
          <AddInfluencerListModal
            detailed_user_task={changeListItem}
            visible={changeListItem !== null}
            influencer_lists={lists}
            close={closeChangeLists}
          />
          <Space
              direction="vertical"
              style={{width: '100%'}}
          >
            <InfluencersFilter
              init={init}
              initialFields={initialFields}
              filters={filters}
              filterUsers={filterUsers}
              categories={categories}
            />
                <div>
                  <Space>
{/*                     <Title level={4}>
                      Total {totalItems} Items
                    </Title> */}
                    <Button loading={dndl} onClick={() => download()} type="primary" shape="round" icon={<DownloadOutlined />}>
                      Download Data
                    </Button>
                  </Space>
                  <div className="menu-float-right">
                  <Pagination 
                        style={{
                          display: 'inline-block'
                        }}
                        current={currentPage}
                        pageSize={Number(pageSize)}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        total={totalItems}
                        onChange={changePage}
                      />
                  </div>
                </div>
              <Table 
                pagination={false && {
                  position: 'topRight',
                  onChange: (page, size) => {setCurrentPage(page);setPageSize(String(size));},
                  current: currentPage,
                  pageSize: pageSize,
                  pageSizeOptions: PAGE_SIZE_OPTIONS,
                  showSizeChanger: true,
                  total: totalItems
                }}
                bordered
                size="middle"
                loading={load}
              columns={columns}
              scroll={{
                x: 1500
              }}
              dataSource={users}
              rowKey="id"
              />
            </Space>
        </ContentWrapper>
      </Col>
    </Row>
  )
}

export default Influencers