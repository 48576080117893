import { CloseCircleOutlined } from '@ant-design/icons'
import { Col, Divider, Row, Card, Typography, Form, Input, Space, Button, Switch, message, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
import GetIgDataFromUsername from "../Common/ApiCall/GetIgDataFromUsername";
import ApiError from '../Common/Models/ApiError';
import { BadNotif, SuccessNotif } from '../Common/Utils/SendNotification';
import ContentWrapper from '../Components/ContentWrapper'
import CreateTask from '../Common/ApiCall/CreateTask'
import Loader from '../Components/Loader';
import AddHashtagTask from '../Common/ApiCall/AddHashtagTask';

const {Title} = Typography
const {Meta} = Card

const formItemLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};
const formTailLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12, offset: 12 },
};

const ExtractUsers = ({setKey}) => {
  const [load, setLoad] = useState(false)
  const [userObj, setUserObj] = useState(null)
  const [username, setusername] = useState('')

  useEffect(()=>{
    setKey()
  }, [])

  function resetUser() {
    setUserObj(null)
    setusername('')
  }

  function createTask(tasks) {
    setLoad(true)
    CreateTask(userObj.username, userObj.id, tasks)
      .then(r => {
        setLoad(false)
        resetUser()
        if(r.success) {
          SuccessNotif('Task Created Successfully.')
          SuccessNotif('Check the status in All processes tab', true)
        } else {
          BadNotif(r)
        }
      })
  }

  async function makeDetailedUserFromUsername(q) {
    setLoad(true)
    let userData = await GetIgDataFromUsername(q);
    /* let userData = {follower_count: 137884825,
      following_count: 139,
      id: "13460080",
      name: "Nike",
      picture: "https://instagram.fmnl4-3.fna.fbcdn.net/v/t51.2885-19/s150x150/26155970_1584552474997482_4541081815552622592_n.jpg?tp=1&_nc_ht=instagram.fmnl4-3.fna.fbcdn.net&_nc_ohc=fUOCZZsGpfEAX-8Haas&oh=13d10ba9173aa9cde3b871665f7dc4d5&oe=60723FCE",
      username: "nike"} */
    setLoad(false)
    if(userData instanceof ApiError) {
      if(userData.message === "User does not exist") {
        BadNotif(null, "Instagram Username does not exist")
      } else {
        BadNotif(null, "Something Went Wrong")
      }
    } else {
      if (userData.biography === "COULD_NOT_ACCESS_THIS_USER") {
        message.error("Please check the username entered and try again")
        return
      }
      setUserObj(userData)
    }
  }

  return(
    <>
      <Row justify="center" gutter={24}>
        <Col md={{span: 24}} lg={{span: 24}}>
          <ContentWrapper marginLess>
            <Title level={3} style={{fontWeight: 'medium'}}>
              Extract followers / followings of any User
            </Title>
            <Divider style={{borderTop: '1px solid black'}} />
            <Row gutter={24} justify="center">
              <Col>
                {(userObj || load) ? (
                  <>
                    <ConfirmCard 
                      load={load}
                      userObj={userObj}
                      resetUser={resetUser}
                      createTask={createTask}
                    />
                  </>
                ) : (
                  <GetIgUser 
                    setUsername={setusername}
                    username={username}
                    makeDetailedUserFromUsername={makeDetailedUserFromUsername}
                  />
                )}
                <Divider />
                <Title level={3}>
                  Extract users from hashtag
                </Title>
                <HashtagTask />
              </Col>
            </Row>
          </ContentWrapper>
        </Col>
      </Row>
    </>
  )
}

const HashtagTask = () => {
  const [load, setLoad] = useState(false)

  function addHashtagTask(values) {
    console.log(values)
    if(!values.hashtag) {
      message.warn("Hashtag value not present")
      return
    }
    if(!values.count) {
      message.warn("Count value not present")
      return
    }
    if(values.count>100_000 && values.count<1) {
      message.warn("Count Value out of range")
      return
    }
    setLoad(true)
    AddHashtagTask(values.hashtag, Number(values.count))
      .then(r => {
        setLoad(false)
        if(r.success) {
          message.success("Task Added Successfully")
        } else {
          BadNotif(r)
        }
      })
  }

  return(
    <Card
      loading={load}
    >
      <Form
        hideRequiredMark
        onFinish={addHashtagTask}
      >
        <Form.Item
          noStyle
        >
          Hashtag
          <Form.Item
            name="hashtag"
            rules={[{
              required: true
            }]}
          >
            <Input 
              placeholder="affiliatemarketing"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          noStyle
        >
          Users to Extract
          <Form.Item
            name="count"
            rules={[{
              required: true,
              min: 1,
              max: 100_000,
              type: 'number'
            }]}
            initialValue={1000}
          >
            <InputNumber 
            />
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

const ConfirmCard = ({
  userObj, resetUser, createTask, load
}) => {
  const [form] = Form.useForm()

  function getTasksType(values) {
    return [
      values.followers ? 
      {
        type: "FOLLOWER",
        total_count: userObj.follower_count,
        get_only_public_users: values.follower_get_only_public_users,
        get_emails: values.follower_get_emails,
        max_allowed: values.follower_max_allowed
      } : null, 
      values.following ? 
      { 
        type: "FOLLOWING", 
        total_count: userObj.following_count,
        max_allowed: values.following_max_allowed,
        get_emails: values.following_get_emails,
        get_only_public_users: values.following_get_only_public_users
      } : null].filter(Boolean)
  }

  function submit() {
    createTask(getTasksType(form.getFieldsValue()))
  }

  useEffect(()=>{
    console.log(userObj)
  }, [])

  if(!userObj) {
    return (
      <Space>
        <Card 
          loading={load}
          style={{
            width: 300,
            marginTop: 0
          }}
        />
      </Space>
    )
  }

  return(
    <Space direction="vertical">
      <Card
        loading={load}
        style={{width: 300, marginTop: 0}}
        cover={(
          <img 
            alt={userObj && userObj.username} 
            src={userObj&&userObj.picture} 
          />
        )}
      >
        {userObj && (
          <Form>
            <Form.Item
              {...formTailLayout}
              className="pb-0 mb-0"
            >
              {userObj.username}
            </Form.Item>
            <Form.Item 
              className="pb-0 mb-0" 
              label="Full Name" 
              {...formItemLayout}
            >
              {userObj.name}
            </Form.Item>
            <Form.Item 
              label="Followers Count" 
              {...formItemLayout} 
              className="pb-0 mb-0"
            >
              {userObj.follower_count}
            </Form.Item>
            <Form.Item 
              label="Following Count" 
              {...formItemLayout} 
              className="pb-0 mb-0"
            >
              {userObj.following_count}
            </Form.Item>
          </Form>
        )}
      </Card>
      <Form
        form={form}
        initialValues={ userObj && {
          followers: true,
          following: true,
          follower_get_only_public_users: true,
          following_get_only_public_users: true,
          follower_get_emails: true,
          following_get_emails: true,
          follower_max_allowed: userObj.follower_count,
          following_max_allowed: userObj.following_count
        }}
      >
        {userObj && (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                noStyle
              >
                Followers
                <Form.Item
                    name="followers"
                    valuePropName={"checked"}
                  >
                    <Switch />
                  </Form.Item>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                noStyle
              >
                Following
                <Form.Item
                  name="following"
                  valuePropName={"checked"}
                >
                  <Switch />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                noStyle
              >
                Only Public Users
                <Form.Item
                  name="follower_get_only_public_users"
                  valuePropName="checked"
                  shouldUpdate
                >
                  <Switch />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                noStyle
              >
                Only Public Users
                <Form.Item
                  name="following_get_only_public_users"
                  valuePropName="checked"
                  shouldUpdate
                >
                  <Switch 
                  />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                noStyle
              >
                Emails
                <Form.Item
                  name="follower_get_emails"
                  valuePropName="checked"
                  shouldUpdate
                >
                  <Switch 
                  />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                noStyle
              >
                Emails
                <Form.Item
                  name="following_get_emails"
                  valuePropName="checked"
                  shouldUpdate
                >
                  <Switch 
                  />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                noStyle
              >
                Users to Extract
                <Form.Item
                  name="follower_max_allowed"
                  rules={[{
                    required: true,
                    min: 1,
                    max: userObj.follower_count,
                    type: 'integer'
                  }]}
                >
                  <InputNumber 
                    size='large'
                    style={{
                      width: 180
                    }}
                    min={1}
                    max={userObj.follower_count}
                  />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                noStyle
              >
                Users to Extract
                <Form.Item
                  name="following_max_allowed"
                  rules={[{
                    required: true,
                    min: 1,
                    max: userObj.following_count,
                    type: 'integer'
                  }]}
                >
                  <InputNumber 
                    size='large'
                    style={{
                      width: 180
                    }}
                    min={1}
                    max={userObj.following_count}
                  />
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Form.Item
          hidden={!userObj}
          shouldUpdate
          noStyle
        >
          {()=>(
            <Button 
              loading={load}
              onClick={submit} 
              disabled={!form.getFieldValue('followers') && !form.getFieldValue('following')}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          )}
        </Form.Item>
        <Form.Item
          hidden={!userObj}
          noStyle
        >
          <Button
            onClick={resetUser}
          >
            Remove User
          </Button>
        </Form.Item>
      </Form>
    </Space>
  )
}

const GetIgUser = ({setUsername, username, makeDetailedUserFromUsername}) => {

  return(
    <Space
      direction="vertical"
    >
      <Title level={4}>
        Enter Instagram Username to Extract
      </Title>
      <Input 
        placeholder="johndoe"
        size="large"
        value={username}
        onChange={e=>setUsername(e.target.value.trim())}
        onPressEnter={()=>{username.length && makeDetailedUserFromUsername(username)}}
      />
      <Button
        disabled={!username.length}
        onClick={()=>makeDetailedUserFromUsername(username)}
        size="large"
        type="primary"
        block
      >
        Continue
      </Button>
    </Space>
  )
}

export default ExtractUsers