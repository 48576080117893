import React from 'react'
import {Table} from 'antd'

const columns = [
  {
    key: 'value',
    dataIndex: 'owner_name',
    title: 'Value'
  }
]

const ShowOwner = ({
  data,
  load
}) => {

  return(
    <Table 
      columns={columns}
      dataSource={data}
      loading={load}
    />
  )
}

export default ShowOwner