import { Col, Divider, Typography, Row, Upload, message, Form, Input, Checkbox, Button, Space, Table } from 'antd'
import {InboxOutlined} from '@ant-design/icons'
import React, { useCallback, useState, useEffect } from 'react'
import ContentWrapper from '../../Components/ContentWrapper'
import PlainContentWrapper from '../../Components/PlainContentWrapper'
import Dropzone from 'react-dropzone'
import {useDropzone} from 'react-dropzone'
import GetAreas from '../../Common/ApiCall/GetAreas'
import GetLeadStatuses from '../../Common/ApiCall/GetLeadStatuses'
import {valuesToStringArray} from '../../Components/helperFunction'
import Loader from '../../Components/Loader'
import AddUsers from '../../Common/ApiCall/AddUsers'
import { BadNotif } from '../../Common/Utils/SendNotification'

const {Dragger} = Upload
const {Title} = Typography

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const columns = [
  {
    key: 'row',
    dataIndex: 'row',
    title: 'Row'
  },
  {
    key: 'ig_id',
    dataIndex: 'ig_id',
    title: 'Ig Id'
  },
  {
    key: 'message',
    dataIndex: 'message',
    title: 'Message'
  },
  {
    key: 'fileName',
    dataIndex: 'fileName',
    title: 'File Name'
  }
]

const badObject = (message, index, fileName, ig_id) => {
  return {
    message: message,
    row: index,
    fileName: fileName,
    ig_id: ig_id
  }
}

const CHECKING_FILES = "Getting Usernames from CSV File"
const UPLOADING = "STORING DATA IN SERVER"

const AddLeads = ({setKey}) => {
  const [form] = Form.useForm()
  const [load, setLoad] = useState(false)
  const [init, setInit] = useState(true)
  const [fileBreak, setFileBreak] = useState(100000)
  const [localFileList, setLocalFileList] = useState([])
  const [fileNumber, setFileNumber] = useState(0)
  const [converting, setconverting] = useState(false)
  const [fileIndex, setFileIndex] = useState(1)
  const [badRows, setBadRows] = useState([])
  const [users, setUsers] = useState([])
  const [areas, setareas] = useState([])
  const [lead_status, set_lead_status] = useState([])
  const [values, setValues] = useState({
    is_tracking: false,
    campaign_name: ''
  })
  const [tobeSubmit, setSubmit] = useState(false)

  const csvToJson = csv => {
    let [firstLine, ...lines] = csv.split('\n');
    firstLine = firstLine.toUpperCase()
    console.log(firstLine)
    return lines.map(line =>
      firstLine.split(',').reduce(
        (curr, next, index) => ({
          ...curr,
          [next]: line.split(',')[index],
        }),
        {}
      )
    );
  };

  const props = {
    name: 'file',
    multiple: true,
    accept: '.csv',
    beforeUpload: file => {
/*       const reader = new FileReader();
      reader.onload = e => {
          console.log(e.target.result);
      };
      reader.readAsText(file);
      // Prevent upload
      return false; */
      if (file.type !== 'text/csv') {
        message.error(`${file.name} is not a csv file`);
      }
      return false
    },
    onChange(info) {
      const { status } = info.file;
      if(info.file.type === 'text/csv') {
        setLocalFileList(info.fileList)
      }
      if (status !== 'uploading') {
//         console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    customRequest: (files) => acceptedFilesFunction(files),
    fileList: localFileList
  };

  const downloadContent = () => {
    let content = ''
    let atag = document.createElement("a");
    let file = new Blob([content], {type: 'text/plain'});
    atag.href = URL.createObjectURL(file)
    atag.download = this.getFileName()
    atag.click();
    this.setState({
      usernamesInString: 0,
      usernamesString: ''
    })
  }

  const getFileName = () => {
    let date = new Date()
    let dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })
    let [{ value: month },,{ value: day },,{ value: year },,{value: hour},,{value: minute},,{value:second}] = dateTimeFormat .formatToParts(date ) ;
    let name = (`${this.state.usernamesInString}-Usernames-${day}-${month}-${year }-${hour}:${minute}:${second}`)
    return(name)
  }

  const convertToTxt = () => {
    let files = this.state.files;
    let number = files.length;
    this.setState({converting: true})
    for(let i=0;i<number;i++) {
      this.addFileToString(files[i], i===number-1)
      this.setState({fileNumber: i+1})
    }
    if(this.state.usernamesInString) {
      this.downloadContent();
    }
    this.setState({
      fileNumber: 0,
      converting: false,
      dataConverted: true
    })
    return true
  }

  const readIndividualFile = (file, finish) => {
    let reader = new FileReader()
    reader.onload = e => {
      const text = e.target.result;
      let jsonData = csvToJson(text)
      console.log(jsonData)
      jsonData = [jsonData.pop(), ...jsonData]
      let data = []
      let bad = []
      jsonData.forEach((element, index) => {
        if(index) {
          let [valid, object] = checkRow(element, index, file.name)
          if(valid) {
            data.push(object)
          } else {
            bad.push(object)
          }
        }
      })
      setBadRows(bad)
      setUsers(data)
      reader.abort()
    }
    reader.readAsText(file)
  }

  const checkValueInArray = (value, key, array) => {
    for(let i=0;i<array.length;i++) {
      if(array[i]["value"].toUpperCase() === value) {
        return array[i]['id']
      }
    }
    return false
  }

  const checkRow = (element, index, fileName) => {
    let status = false
    let object = {
      bio: element['BIO'],
      category: element['CATEGORY'],
      contractor_type: element[('Contractor type').toUpperCase()],
      email: element[('Email').toUpperCase()],
      ig_id: element[('Ig ID').toUpperCase()],
      instagram_account_owner: element[("Instagram Account Owner").toUpperCase()],
      phone_number: element[('Phone Number').toUpperCase()],
      username: element[('Username').toUpperCase()]
    }
    if(!element[('Area').toUpperCase()] || !element[('Lead Status').toUpperCase()]) {
      object = badObject(
        `${!element['AREA'] ? 'Area' : ''} ${(!element['AREA'] && !element['LEAD STATUS']) ? "And" : ''} ${!element['LEAD STATUS'] ? 'Lead Status' : ''} not present`,
        index, 
        fileName,
        element['IG ID']
      )
    } else {
      if(checkValueInArray(element['AREA'].toUpperCase(), "area", areas)) {
        object['area_id'] = checkValueInArray(element['AREA'].toUpperCase(), "area", areas)
        if(checkValueInArray(element['LEAD STATUS'].toUpperCase(), "lead_status", lead_status)) {
          status = true
          object['lead_status_id'] = checkValueInArray(element['LEAD STATUS'].toUpperCase(), "lead_status", lead_status)
        } else {
          object = badObject('Invalid Lead Status Value', index, fileName, element['IG ID'])        
        }
      } else {
        object = badObject('Invalid Area Value', index, fileName, element['IG ID'])
      }
    }
    return [status, object]
  }

  const readFiles = async () => {
    console.time('read_files')
    setLoad(true)
    setconverting(true)
    await localFileList.forEach((file, index) => {
      setFileIndex(index+1)
      readIndividualFile(file.originFileObj)
    })
    setconverting(false)
    setLoad(false)
    console.timeEnd('read_files')
  }

  function acceptedFilesFunction(acceptedFiles) {
    console.log('Render')
    console.log(acceptedFiles)
  }

  function submitForm(values) {
    if(localFileList.length < 1) {
      message.error("Add File to Continue")
      return
    }
    setValues(values)
    setSubmit(true)
    startFileReading()
  }

  const startFileReading = async () => {
    setLoad(true)
    await readFiles()
    setLoad(false)
  }

  const reset = (stay = true) => {
    setLocalFileList([])
    if(!stay) {
      setUsers([])
      setBadRows([])
      form.resetFields()
    }
  }

  function getAreas() {
    setInit(true)
    GetAreas()
    .then(r => {
      setInit(false)
      if(r.success) {
        setareas(r.data)
        // setareas(valuesToStringArray(r.data, 'value'))
      }
    })
  }

  function getLeadStatus() {
    setInit(true)
    GetLeadStatuses()
      .then(r => {
        setInit(false)
        if(r.success) {
          set_lead_status(r.data)
          // set_lead_status(valuesToStringArray(r.data, 'value'))
        }
      })
  }

  const initFunction = () => {
    setKey()
    getAreas()
    getLeadStatus()
  }

  useEffect(()=>{
    initFunction()
  }, [])

  useEffect(()=>{

  })

  useEffect(()=>{
    if(tobeSubmit) {
      if(users.length===0) {
        message.error("No Valid Users to Start Campaign")
        setLoad(false)
      } else if (badRows.length>0) {
        message.warning("Fix Below Errors and then Continue Again.")
        reset()
        setLoad(false)
      } else {
        setLoad(true)
        AddUsers(users, values.campaign_name, values.is_tracking)
          .then(r => {
            setLoad(false)
            if(r.success) {
              message.success("Campaign Started Successfully")
              reset(false)
            } else {
              BadNotif(r)
            }
          })
      }
      setSubmit(false)
    }
  }, [users])

  useEffect(()=>console.log(badRows), [badRows])

  return(
    <ContentWrapper marginLess>
    <Title 
      style={{fontWeight: 'medium'}} 
      level={3}
    >
      Add Leads
    </Title>
    <Divider style={{borderTop: '1px solid black'}} />

    {
      init && (
        <Loader />
      )
    }

    {users.length>0 && (
      <Title level={4}>
        {`Total Users: ${users.length + badRows.length} ${badRows.length ? `(Valid -> ${users.length})` : ''}`}
      </Title>
    )}

    <Dragger
      {...props}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
    </Dragger>
    <br />
    <Form
      onFinish={submitForm}
      form={form}
      initialValues={{
        campaign_name: values.campaign_name,
        is_tracking: values.is_tracking
      }}
    >
      <Form.Item
        noStyle
      >
        <Row>
          <Col lg={{span: 10}} md={{span: 16}}>
            Campaign name
            <Form.Item
              rules={[{required: false}]}
              name="campaign_name"
            >
              <Input 
                placeholder="Enter Your Campaign Name Here"
              />
            </Form.Item>
          </Col>
          <Col lg={{span: 10, offset: 1}} md={{span: 16, offset: 1}}>
            <Form.Item
              name="is_tracking"
            >
              <Checkbox />&nbsp;&nbsp;Tracking 
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            disabled={init || localFileList.length<1 || load}
            loading={load}
          >
            {
              load ? (
                converting ? CHECKING_FILES : UPLOADING
              ) : "Submit"
            }
          </Button>
{/*           <Button
            hidden={badRows.length === 0}
            onClick={startFileReading}
            type="dashed"
          >
            Read Files Again
          </Button>
          <Button
            hidden={users.length===0 || badRows.length===0}
            onClick={reset}
          >
            Reset
          </Button> */}
        </Space>
      </Form.Item>
    </Form>
    {
      badRows.length > 0 ? (
        <Table 
          pagination={{
            defaultPageSize: 50,
            pageSizeOptions: [25, 50, 100]
          }}
          dataSource={badRows}
          columns={columns}
          loading={badRows.length>0 && load}
          rowKey={'Ig Id'}
        />
      ) : null
    }
  </ContentWrapper>
  )

  return(
    <PlainContentWrapper
      marginLess
    >
      <Row justify="center" gutter={24}>
        <Col md={{span: 24}} lg={{span: 20}}>
          <ContentWrapper marginLess>
            <Title 
              style={{fontWeight: 'medium'}} 
              level={3}
            >
              Add Leads
            </Title>
            <Divider style={{borderTop: '1px solid black'}} />
            <Dragger
              {...props}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
{/*             <Dropzone onDrop={acceptedFilesFunction}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Click or drag file to this area to upload</p>
                  </div>
                </section>
              )}
            </Dropzone> */}
            <br />
            <Form>
              <Form.Item
                noStyle
              >
                Campaign name
                <Form.Item>
                  <Input 
                    placeholder="Enter Your Campaign Name Here"
                  />
                </Form.Item>
              </Form.Item>
              <Form.Item
              >
                <Checkbox />&nbsp;&nbsp;Tracking 
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </ContentWrapper>
        </Col>
      </Row>
    </PlainContentWrapper>
  )
}

export default AddLeads