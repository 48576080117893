import React, { useEffect } from 'react'
import {Typography, Tabs, Form, Button, Divider} from 'antd'
import Area from './Area'
import LeadStatus from './LeadStatus'
import Owner from './Owner'
import ContentWrapper from '../../Components/ContentWrapper'

const {Title} = Typography
const {TabPane} = Tabs

const ManageData = ({setKey}) => {

  useEffect(()=>{
    setKey()
  }, [])

  return(
    <ContentWrapper
      marginLess
    >
      <Title>
        Manage Data
        <Button>
        </Button>
      </Title>
      <Divider />
      <Tabs
        type="card"
      >
        <TabPane
          tab="Area"
          key="1"
        >
          <Area />
        </TabPane>
        <TabPane
          tab="Lead Status"
          key="2"
        >
          <LeadStatus />
        </TabPane>
        <TabPane
          tab="Owner"
          key="3"
        >
          <Owner />
        </TabPane>
      </Tabs>
    </ContentWrapper>
  )
}

export default ManageData