import {
  Col,
  Row,
  Typography,
  Divider,
  Button,
  Table,
  message,
  Spin
} from 'antd'
import React, { useEffect, useState } from 'react'
import AddInfluencerLists from '../../Common/ApiCall/AddInfluencerLists'
import DownloadUsersInfluencerList from '../../Common/ApiCall/DownloadUsersInfluencerList'
import GetInfluencerLists from '../../Common/ApiCall/GetInfluencerLists'
import { BadNotif, SuccessNotif } from '../../Common/Utils/SendNotification'
import ContentWrapper from '../../Components/ContentWrapper'
import { InfluencersProps } from '../Influencers/Influencers'
import AddListModal from './AddListModal'

const {Title} = Typography

const InfluencerList = ({
  setKey,
  myKey
}: InfluencersProps) => {
  const [data, setData] = useState([])
  const [load, setLoad] = useState(true)
  const [vis, setVis] = useState(false)
  const [dndl, setDndl] = useState(false)

  const closeModal = () => setVis(false)
  const openModal = () => setVis(true)

  function getData() {
    setLoad(true)
    GetInfluencerLists()
      .then(r => {
        setLoad(false)
        if(r.success) {
          setData(r.data.lists)
        }
      })
  }

  function addData(q) {
    if(q.length<1) {
      message.warn("Enter Value to Continue")
      return
    }
    setLoad(true)
    AddInfluencerLists(q)
      .then(r => {
        setLoad(false)
        if(r.success) {
          message.success("Added Lead Status Successfully.")
          closeModal()
          getData()
        } else {
          BadNotif(r)
        }
      })
  }
  
  useEffect(() => {
    if (myKey != "14") {
      setKey()
    }
  }, [])

  useEffect(() => {
    if (myKey == '14') {
      getData()
    }
  }, [myKey])

  function download(i: number) {
    setDndl(true)
    DownloadUsersInfluencerList(i)
      .then(r => {
        setDndl(false)
        if(r.success) {
          SuccessNotif('Downloading Resource')
          window.open(r.data)
        } else {
          BadNotif(r)
        }
      })
  }

  const columns = [
    {
      key: 'value',
      title: 'Value',
      dataIndex: 'value'
    },
    {
      key: 'actions',
      render: (value: any) => (
        <Button
          onClick={() => download(value.id)}
        >
          Download All Users
        </Button>
      )
    }
  ]

  return (
    <Row gutter={24}>
      <Col span={24}>
        <ContentWrapper>
        <AddListModal 
          visible={vis}
          addLeadStatus={addData}
          close={closeModal}
        />
          <Title level={3}>
            Influencers List
          </Title>
          <Divider />
          <Button
            type="primary"
            onClick={openModal}
          >
            CREATE NEW LIST
          </Button>
          <br />
          {
            dndl && (
              <div className="has-text-centered">
                Preparing Users List... <Spin />  
              </div>
            )
          }
          <Table 
            dataSource={data}
            columns={columns}
            loading={load}
          />
        </ContentWrapper>
      </Col>
    </Row>
  )
}

export default InfluencerList