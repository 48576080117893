import React, { useEffect, useState } from 'react'
import ContentWrapper from '../Components/ContentWrapper'
import PlainContentWrapper from '../Components/PlainContentWrapper'
import { Col, Row, Typography, Divider, Table, Progress, Tooltip, Space, Button } from 'antd'
import StopTask from '../Common/ApiCall/StopTask'
import ContinueTask from '../Common/ApiCall/ContinueTask.js'
import { BadNotif, SuccessNotif } from '../Common/Utils/SendNotification'
import GetProcesses from '../Common/ApiCall/GetProcesses'

const {Title} = Typography

const AllProcesses = ({setKey}) => {
  const [tasks, setTasks] = useState([])
  const [load, setLoad] = useState(false)

  function renderDate(epoch) {
    let d = new Date(0)
    d.setUTCSeconds(epoch)
    d = d.toISOString().split('T')[0]
    console.log(d)
    return d
  }

  function startLoading() {setLoad(true)}
  function stopLoading() {setLoad(false)}

  function stopTask(id) {
    startLoading()
    StopTask(id)
      .then(r => {
        stopLoading()
        getTasks()
        if(r.success) {
          SuccessNotif('Task Stopped Successfully.')
        } else {
          BadNotif(r)
        }
      })
  }

  function startTask(id) {
    startLoading()
    ContinueTask(id)
      .then(r => {
        stopLoading()
        getTasks()
        if(r.success) {
          SuccessNotif('Task Continued')
        } else {
          BadNotif(r)
        }
      })
  }

  const columns = [
    {
      key: 'category',
      dataIndex: 'category',
      title: 'Type',
      render: (category) => category.name
    },
    {
      key: 'datetime',
      dataIndex: 'date_time_submitted',
      title: 'Date Submitted',
      render: (date) => renderDate(date)
    },
    {
      key: 'progress',
      dataIndex: 'progress',
      title: 'Progress So Far',
      render: (progress) => (
        <Tooltip title={progress.done + " / " + progress.total }>
          <Progress percent={Math.round(progress.done*100/progress.total)} />
        </Tooltip>
      )
    },
    {
      key: 'action',
      title: 'Action',
      render: (item) => (
        <Space>
          {Math.round(item.progress.done*100/item.progress.total)<100 ? (
            item.is_stopped ? (
              <Button type="primary" onClick={()=>startTask(item.id)}>
                Continue
              </Button>
            ) : (
              <Button type="danger" onClick={()=>stopTask(item.id)}>
                Stop
              </Button>
            )
          ) : null}
        </Space>
      )
    }
  ]

  function getTasks() {
    setLoad(true)
    GetProcesses()
      .then(r => {
        setLoad(false)
        if(r.success) {
          setTasks(r.data)
        }
      })
  }

  useEffect(()=>{
    setKey()
    getTasks()
  }, [])

  return(
    <>
      <Row justify="center" gutter={24}>
        <Col md={{span: 24}} lg={{span: 24}}>
          <ContentWrapper marginLess>
            <Title level={3} style={{fontWeight: 'medium'}}>
              Tasks
            </Title>
            <Divider style={{borderTop: '1px solid black'}} />
            <Table 
              loading={load}
              columns={columns}
              dataSource={tasks}
            />
          </ContentWrapper>
        </Col>
      </Row>
    </>
  )
}

export default AllProcesses