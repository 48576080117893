import {
    Form,
    Input,
    Typography,
    Timeline,
    Table,
    Divider,
    message,
    Select,
    DatePicker,
    Tabs,
    Button,
    Col,
    Row,
    Switch,
    InputNumber,
    Space,
    Skeleton,
    Popconfirm,
    Result,
} from "antd";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import GetAreas from "../../Common/ApiCall/GetAreas";
import GetCampaigns from "../../Common/ApiCall/GetCampaigns";
import GetCRMUserDetails from "../../Common/ApiCall/GetCRMUserDetails";
import GetLeadStatuses from "../../Common/ApiCall/GetLeadStatuses";
import { BadNotif } from "../../Common/Utils/SendNotification";
import ContentWrapper from "../../Components/ContentWrapper";
import Loader from "../../Components/Loader";
import LeadsEnums from "./LeadsEnums";
import UpdateUserLeadStatus from "../../Common/ApiCall/UpdateUserLeadStatus";
import moment from "moment";
import UpdateUserTracking from "../../Common/ApiCall/UpdateUserTracking";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import UpdateCRMUser from "../../Common/ApiCall/UpdateCRMUser";
import UpdateUserNextStepDate from "../../Common/ApiCall/UpdateUserNextStepDate";
import { Link } from "react-router-dom";
import AddTask from "../ManageTasks/AddTask";

const { Title } = Typography;
const { TabPane } = Tabs;

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};
const formTailLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12, offset: 6 },
};

const rowProps = {
    gutter: 24,
};

const colProps = {
    md: { span: 11, offset: 1 },
    lg: { span: 7, offset: 1 },
};

const dateFormat = "DD/MM/YYYY";

function date(date_1) {
    let date = new Date(date_1);
    return moment(date, dateFormat);
}

function datePrintable(d) {
    return moment(d).format(dateFormat);
}

const trackingColumns = [
    {
        key: "date",
        dataIndex: "date",
        title: "Date",
        render: (i) => datePrintable(i),
    },
    {
        key: "change_type",
        title: "Change Type",
        dataIndex: "change_type",
    },
    {
        key: "before",
        title: "Before",
        dataIndex: "before",
    },
    {
        key: "after",
        title: "After",
        dataIndex: "after",
    },
];

/*
Lead Object  (P1 upto Hubspot Owner)
- instagram_account_owner - **Text**
- first_name: **Text**
- last_name: **Text**
- area: **Pick List** → Montreal, Boston, Toronto, Out of Zone
- lead_status: **Pick List** → Working, Unqualified, Qualified, Engaged, Interested, Not Interested, Not in Service Area, with SDR, Sent to Hubspot
-+ lead_status_change_date → Epoch **or null**
- next_step_date: Epoch **or null**
- step_date_comments: [{date_changed: DateTime.now, comment: **Text, date_changed_to: Epoch**}]
On 10th April (**date_changed**), the next step date was changed to 13th April (**date_changed_to/next_step_date**) with comment "User didn't pick up the phone" (**comment**)
- hubspot_owner: **Text**
- contractor_type: **Text**
- is_tracking: **Bool** (Default False)
-+ campaign_name: **Text**
- website: **Text**
- category: **Text**
- phone_number: **Text**
- email: **Text**
-+ created_at: Epoch
-+ ig_id: text
- bio: text
- username: text
- tracking_changes: ["Post count increased from 23 to 24 on 24rd March, 2021", "Posted a story on 24th March, 2021"]
*/

const ViewEditLead = ({ setKey, ...rest }) => {
    const [userId, setUserId] = useState(null);
    const [load, setLoad] = useState(false);
    const [leadStatus, setLeadStatus] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [areas, setAreas] = useState([]);
    const [init, setInit] = useState(true);
    const [details, setDetails] = useState(null);
    const [userForm] = Form.useForm();
    const [leadForm] = Form.useForm();
    const [backFilters, setBackFilters] = useState({});
    const [notFound, setNotFound] = useState(false);
    const [bMessage, setBMessage] = useState("");

    const badFunction = (message) => {
        setBMessage(message);
        setNotFound(true);
    };

    const changeUserFormat = (user) => {
        user.lead_status_change_date = date(user.lead_status_change_date);
        user.created_at = date(user.created_at);
        user.next_step_date = date(user.next_step_date);
        return user;
    };

    const getDetails = (id = userId) => {
        setLoad(true);
        console.log(id, userId);
        GetCRMUserDetails(id).then((r) => {
            setLoad(false);
            if (r.success) {
                let u = changeUserFormat(r.data);
                userForm.setFieldsValue(u);
                leadForm.setFieldsValue(r.data);
                setDetails(u);
            } else {
                if (r && r.errors[0].message) {
                    badFunction(r.errors[0].message);
                } else {
                    badFunction();
                }
                BadNotif(r);
            }
        });
    };

    function getLeadStatus() {
        setInit(true);
        GetLeadStatuses().then((r) => {
            setInit(false);
            if (r.success) {
                setLeadStatus(r.data);
            }
        });
    }

    function getAreas() {
        setInit(true);
        GetAreas().then((r) => {
            setInit(false);
            if (r.success) {
                setAreas(r.data);
            }
        });
    }

    function getCampaigns() {
        setInit(true);
        GetCampaigns().then((r) => {
            setInit(false);
            if (r.success) {
                setCampaigns(r.data);
            }
        });
    }

    const initialCalls = async (id) => {
        setInit(true);
        getLeadStatus();
        getAreas();
        getCampaigns();
        await getDetails(id);
        setInit(false);
    };

    useEffect(() => {
        if (rest && rest.match.params.id) {
            setUserId(rest.match.params.id);
            if (rest.location.state && rest.location.state.filters) {
                setBackFilters(rest.location.state.filters);
            }
        }
        initialCalls(rest.match.params.id);
        setKey();
    }, []);

    function addUserNextStepDate(values) {
        setLoad(true);
        UpdateUserNextStepDate(
            userId,
            Number(date(values.new_next_step_date)) / 1000,
            values.comment
        ).then((r) => {
            if (r.success) {
                message.success("Step Added");
                getDetails();
            } else {
                BadNotif(r);
            }
        });
    }

    const commonUpdate = (values) => {
        let editableFields = {
            instagram_account_owner: values.instagram_account_owner,
            first_name: values.first_name,
            last_name: values.last_name,
            area_id: values.area_id,
            hubspot_owner: values.hubspot_owner,
            contractor_type: values.contractor_type,
            website: values.website,
            category: values.category,
            phone_number: values.phone_number,
            email: values.email,
            bio: values.bio,
            username: values.username,
        };
        return editableFields;
    };

    function updateUser(values) {
        setLoad(true);
        UpdateCRMUser(userId, commonUpdate(values)).then((r) => {
            setLoad(false);
            if (r.success) {
                message.success("User Details Updated");
                getDetails();
            } else {
                BadNotif(r);
            }
        });
    }

    function changeLeadStatus(lead_id) {
        setLoad(true);
        UpdateUserLeadStatus(userId, lead_id).then((r) => {
            setLoad(false);
            if (r.success) {
                message.success("Lead Status Changed");
                getDetails();
            } else {
                BadNotif(r);
            }
        });
    }

    function startTracking() {
        setLoad(true);
        UpdateUserTracking(userId, true).then((r) => {
            setLoad(false);
            if (r.success) {
                message.success("Started Tracking for User");
                getDetails();
            } else {
                BadNotif(r);
            }
        });
    }

    function stopTracking() {
        setLoad(true);
        UpdateUserTracking(userId, false).then((r) => {
            setLoad(false);
            if (r.success) {
                message.success("Stopped Tracking for User");
                getDetails();
            } else {
                BadNotif(r);
            }
        });
    }

    function clear() {
        userForm.setFieldsValue(details);
    }

    function emptyDate() {
        const dateFormat = "YYYY/MM/DD";
        let date = new Date(null);
        return moment(date, dateFormat);
    }

    function renderDate(epoch) {
        let d = new Date(0);
        d.setUTCSeconds(epoch);
        d = d.toISOString().split("T")[0];
        return d;
    }

    if (notFound) {
        return (
            <ContentWrapper marginLess>
                <Result
                    title={bMessage}
                    status="error"
                    extra={
                        <Button type="primary">
                            <Link
                                to={{
                                    pathname: "/leads",
                                    state: {
                                        filters: backFilters,
                                    },
                                }}
                            >
                                Go Back
                            </Link>
                        </Button>
                    }
                />
            </ContentWrapper>
        );
    }

    return (
        <ContentWrapper marginLess>
            <Title level={3}>
                View/ Edit Lead
                <Space className="is-pulled-right">
                    <Button
                        onClick={() => getDetails()}
                        title="Refresh User Data"
                    >
                        <ReloadOutlined />
                    </Button>
                    <Button>
                        <Link
                            to={{
                                pathname: "/leads",
                                state: {
                                    filters: backFilters,
                                },
                            }}
                        >
                            Back
                        </Link>
                    </Button>
                </Space>
            </Title>
            <Divider />
            {init || load ? (
                <Skeleton />
            ) : (
                <Tabs type="card">
                    <TabPane tab="Details" key="1">
                        <Form
                            form={userForm}
                            hideRequiredMark
                            onFinish={updateUser}
                            initialValues={details === null ? {} : details}
                        >
                            <Row {...rowProps}>
                                <ColField>
                                    <Form.Item
                                        name="instagram_account_owner"
                                        label="Instagram Account Owner"
                                    >
                                        <Input />
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form.Item
                                        name="first_name"
                                        label="Primary Contact First Name"
                                    >
                                        <Input />
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form.Item
                                        label="Primary Contact Last Name"
                                        name="last_name"
                                    >
                                        <Input />
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form.Item
                                        label="Area"
                                        name="area_id"
                                        rules={[{ required: true }]}
                                    >
                                        <Select
                                            placeholder="Select a Area"
                                            style={{ maxWidth: "400px" }}
                                        >
                                            {areas.map((area) => (
                                                <Select.Option
                                                    value={area.id}
                                                    key={area.id}
                                                    title={area.value}
                                                >
                                                    {area.value}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form
                                        initialValues={details ? details : {}}
                                        onFinish={changeLeadStatus}
                                        form={leadForm}
                                        hideRequiredMark
                                    >
                                        <Form.Item
                                            rules={[{ required: true }]}
                                            label="Lead Status"
                                            name="lead_status_id"
                                        >
                                            <Select
                                                placeholder="Select a Lead Status"
                                                onChange={changeLeadStatus}
                                            >
                                                {leadStatus.map(
                                                    (lead_status) => (
                                                        <Select.Option
                                                            value={
                                                                lead_status.id
                                                            }
                                                            key={lead_status.id}
                                                            title={
                                                                lead_status.value
                                                            }
                                                        >
                                                            {lead_status.value}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </ColField>
                                <ColField>
                                    <Form.Item
                                        name="lead_status_change_date"
                                        label="Lead Status Chenge Date"
                                        disabled
                                    >
                                        <DatePicker
                                            disabled
                                            format={dateFormat}
                                        />
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form.Item
                                        name="hubspot_owner"
                                        label="Hubspot Owner"
                                    >
                                        <Input />
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form.Item
                                        label="Contractor Type"
                                        name="contractor_type"
                                    >
                                        <Input />
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form.Item>
                                        {details ? (
                                            details.is_tracking ? (
                                                <Popconfirm
                                                    okText="Yes"
                                                    title="Are you sure you want to stop Tracking for this User?"
                                                    cancelText="No"
                                                    onConfirm={() =>
                                                        stopTracking(details.id)
                                                    }
                                                >
                                                    <Button type="danger">
                                                        Stop Tracking
                                                    </Button>
                                                </Popconfirm>
                                            ) : (
                                                <Button
                                                    onClick={startTracking}
                                                    type="primary"
                                                >
                                                    Start Tracking
                                                </Button>
                                            )
                                        ) : null}
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form.Item
                                        label="Campaign Name"
                                        name="campaign_id"
                                        rules={[{ required: true }]}
                                    >
                                        <Select
                                            style={{ maxWidth: "400px" }}
                                            disabled
                                        >
                                            {campaigns.map((area) => (
                                                <Select.Option
                                                    value={area.id}
                                                    key={area.id}
                                                    title={area.value}
                                                >
                                                    {area.value}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form.Item label="Website" name="website">
                                        <Input />
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form.Item label="Category" name="category">
                                        <Input />
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form.Item
                                        name="phone_number"
                                        label="Phone"
                                    >
                                        <Input />
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form.Item name="email" label="Email">
                                        <Input />
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form.Item
                                        name="created_at"
                                        label="Created At"
                                    >
                                        <DatePicker
                                            disabled
                                            format={dateFormat}
                                        />
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form.Item name="ig_id" label="Ig ID">
                                        <Input disabled />
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form.Item name="username" label="Username">
                                        <Input />
                                    </Form.Item>
                                </ColField>
                                <ColField>
                                    <Form.Item name="bio" label="Bio">
                                        <Input.TextArea />
                                    </Form.Item>
                                </ColField>
                            </Row>
                            <Row>
                                <ColField>
                                    <Form.Item shouldUpdate>
                                        {() => (
                                            <Space>
                                                <Button
                                                    type="primary"
                                                    disabled={
                                                        !userForm.isFieldsTouched()
                                                    }
                                                    htmlType="submit"
                                                >
                                                    Update
                                                </Button>
                                                <Button
                                                    type="dashed"
                                                    onClick={clear}
                                                >
                                                    Reset
                                                </Button>
                                                {details && (
                                                    <Button
                                                        href={
                                                            "https://instagram.com/" +
                                                            details.username
                                                        }
                                                        target="_blank"
                                                    >
                                                        Open User Profile
                                                    </Button>
                                                )}
                                            </Space>
                                        )}
                                    </Form.Item>
                                </ColField>
                            </Row>
                            <Divider />
                        </Form>
                        <Divider />
                        <Title level={3}>
                            Add Task
                        </Title>
                        <AddTask 
                            userId={userId}
                        />
{/*                         <Row>
                            <Col>
                                <Form
                                    layout="inline"
                                    hideRequiredMark
                                    onFinish={addUserNextStepDate}
                                >
                                    <Form.Item
                                        rules={[{ required: true }]}
                                        label="Add Step"
                                        name="new_next_step_date"
                                    >
                                        <DatePicker format={dateFormat} />
                                    </Form.Item>
                                    <Form.Item
                                        rules={[{ required: true }]}
                                        name="comment"
                                        label="Comment"
                                    >
                                        <Input.TextArea />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                        >
                                            Add Step
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                        <br />
                        <Timeline>
                            {details &&
                                details.step_date_comments.map(
                                    (item, index) => (
                                        <Timeline.Item>
                                            {`On ${item.date_changed.slice(
                                                0,
                                                10
                                            )}, the next step date was changed to ${item.date_changed_to.slice(
                                                0,
                                                10
                                            )} wih comment ${item.comment}`}
                                        </Timeline.Item>
                                    )
                                )}
                        </Timeline> */}
                    </TabPane>
                    {/*                     <TabPane  
                        tab="Tracking" 
                        key="3"
                        
                    >
                    {details && (
                                                  <Button
                                                    href={'https://instagram.com/'+details.username}
                                                    target="_blank"
                                                  >
                                                    Open User Profile
                                                  </Button>
                                                )}
                        <Table
                            loading={init}
                            columns={trackingColumns}
                            dataSource={
                                details ? details.tracking_changes : null
                            }
                        />
                    </TabPane> */}
                </Tabs>
            )}
        </ContentWrapper>
    );
};

const ColField = ({ children }) => {
    return <Col {...colProps}>{children}</Col>;
};

export default ViewEditLead;
